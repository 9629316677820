import React, { PureComponent } from 'react';
import LoadingImagePlaceholder from './LoadingImagePlaceholder';
import '../css/content_section.scss';
import helperFunctions from '../tools/helperFunctions';
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class ContentObject extends PureComponent{

    render(){    
        return(
            <div className="content-container">
            
                <button className="content-object" onClick={()=>{    
                         mixpanel.track("resource_selected",{
                                "title": this.props.obj.title,
                                "uuid": this.props.obj.uuid,
                                "location": window.location.pathname
                            })
                        if(this.props.queryResource){
                            window.open('/resource/' + helperFunctions.titleToURL(this.props.obj.base_title)+ '?l=' + this.props.obj.language, '_blank');

                        }
                        else{
                            window.location.href = '/resource/' + helperFunctions.titleToURL(this.props.obj.base_title)+ '?l=' + this.props.obj.language
                        }
                    }}>
                    <div className="resource-container">
                    
                        <div className="resource-item">
                            < LoadingImagePlaceholder resource={this.props.obj}/>
                             {helperFunctions.isResourceNew(this.props.obj.created_ts) &&
                                <div className="new-badge">
                                    <span className="new-badge-text" style={{fontSize: (this.props.obj.language === 's'? '9px': '11.7px')}}>{this.props.obj.language === 's'? 'NUEVO':'NEW'}</span>
                                    <img className="new-badge-img" alt="This resource was recently added!" src="https://cc-cdn.pss.co/assets/bdg_star_med.svg"/>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="multi-line-label">
                        <h6 className="text-max-line">{this.props.obj.title}</h6>
                    </div>
                </button>
                    
            </div>
        )
    }
}

export default ContentObject;