import LocalizedStrings from 'react-localization';

let localization = new LocalizedStrings({
  en:{
    /* General */
    "gen.log_in": "Log In",
    "gen.account": "Account",
    "gen.create_account": "Create an Account",
    "gen.log_in__1": "Sign In",
    "gen.log_out": "Logged out",
    "gen.register": "Register",
    "gen.first_name": "First Name",
    "gen.last_name": "Last Name",
    "gen.state": "State",
    "gen.optional": "optional",
    "gen.input_error": "Please enter a value for this field",
    "gen.authenticating": "Authenticating",
    "gen.pp": "Privacy Policy",
    "gen.tos": "Terms of Service",
    "gen.contact_us": "Contact Us",
    "gen.recent": "Most Recent",
    "gen.sort_desc": "Title: (A-Z)",
    "gen.sort_asc": "Title: (Z-A)",
    "gen.description": "Description",
    "gen.domain": "Domain",
    "gen.download": "Download",
    "gen.en": "English",
    "gen.es": "Spanish",
    "gen.bi": "Bilingual",
    "gen.grade_levels": "Suggested Grade Levels",
    "gen.order_print": "Order a Print!",
    "gen.pricing": "${} ea.",
    "gen.related_resources": "Related Resources",
    "gen.save": "Save",
    "gen.saved": "Saved!",
    "gen.standard": "Standard",
    "gen.subject": "Subject",
    "gen.subscribe_to_download": "Subscribe to Download",
    "gen.tos_and_pp": "Terms of Service and Privacy Policy",
    "gen.language": "Language",
    "gen.quantity": "Qty: {0}",
    "gen.remove": "Remove",
    "gen.size": "Size",
    "gen.join": "Join folio!",

    "gen.loading": "Loading",
    "gen.filters": "Filters",
    "gen.share": "Share",
    "gen.grades": "Grades",
    "gen.close": "Close",
    "gen.resource": "resource",
    "gen.one": "one",
    "gen.earned": "earned",
    "gen.pending": "pending",
    "gen.subscribe": "Subscribe",

    /*Toast Messages */
    "toast.log_out": "You have been successfully logged out.",
    "toast.log_in_header": "Logged In",
    "toast.log_in_body": "Welcome to folio {0}!",
    "toast.log_out_header": "Logged out",
    "toast.log_out_body": "You have been successfully logged out.",
    "toast.copied_to_clipboard": "Link Copied to Clipboard!",
    "toast.create_account_body": "Create an account to save this resource for later!",
    "toast.create_account_header": "Create an account first",
    "toast.resource_added_to_cart_body": "Use the cart icon in the navigation bar to check out!",
    "toast.resource_added_to_cart_header": "Resource/Resources added to the cart!",
    "toast.resource_downloaded_body": "Check your downloads folder to access the resource.",
    "toast.resource_downloaded_header": "Resource downloaded!",
    "toast.resource_save_error_header": "We were unable to update this resource.",
    "toast.resource_saved_body": "Successfully added to your saved resources!",
    "toast.resource_saved_header": "Resource saved",
    "toast.try_again": "Try again in a few moments.",
    "toast.collection_saved_header": "Collection Saved",
    "toast.collection_saved_body": "This collection successfully added to your saves",
    "toast.collection_unsaved_header": "Collection Unsaved",
    "toast.collection_unsaved_body": "This collection has been removed from your saves.",
    "toast.collection_download_error_body": "We were unable to update this collection. Try again in a few moments.",
    "toast.link_copied": "Link Copied to Clipboard!",
    "toast.referral_body": "Thanks for inviting {0} to Folio!",
    "toast.referral_error_pending": "There is already a pending invite for {0} to Folio. Invites are only valid for 7 days.",
    "toast.referral_error_exists": "A user with the email {0} already exists.",
    "toast.referral_header": "Invite Successfully Sent!",
    "toast.gen_error_header": "Oops! Something went wrong.",
    "toast.gen_error_body": "Looks like something went wrong! Let's try that again.",
    "toast.resource_download_error_body": "The resource could not be downloaded. Try again in a few moments.",
    "toast.expired_session_header": "Session expired",
    "toast.expired_session_body": "You have been logged out due to an expired session. Log back in to continue!",
    "toast.search_error_header": "Invalid Search",
    "toast.search_error_body": "Please submit a valid search term or phrase.",
    "toast.added_to_cart_body": "Use the cart icon in the navigation bar to check out!",
    "toast.added_to_cart_header": "{0} {1} added to the cart!",
  
    /*Authentication */
    "auth.create_account": "Don't have an account?",
    "auth.email": "Continue with email",
    "auth.log_in": "Already a member?",

    /*Cart */
    "cart.check_out": "Check Out Now",
    "cart.empty_body": "Try this {0} or this {1}.",
    "cart.empty_header": "Nothing's in your cart!",
    "cart.free": "Free!",
    "cart.new_order_total": "New Order Total: ${0}",
    "cart.order_summary": "Order Summary",
    "cart.order_total": "Order Total: ${0}",
    "cart.shipping": "Shipping",
    "cart.shipping_disclaimer": "We currently only ship within the U.S.",
    "cart.shopping_cart": "Shopping Cart",
    "cart.subscribe_cta": "Subscribe Today!",
    "cart.subscriber_promo": "Become a subscriber and get free shipping on all orders! Just $5/month!",
    "cart.subtotal": "Subtotal({0} items)",
    "cart.tax_disclaimer": "Tax calculated at Checkout",

    /*Contact Us */
    "contact.message_1": "We would love to hear from you!",
    "contact.message_2": "Have a question, comment, suggestion, or interested in your school becoming part of folio?",
    "contact.message_3": "Fill out the form and a member of our team will get back to you shortly!",
    "contact.support_email": "Email: support@foliok12.com",

    /*Collection */
    "collection.collection_header": "The Collection",

    /* Navigation */
    "nav.home": "Home",
    "nav.library": "Library",
    "nav.collections": "Collections",
    "nav.saved": "Saved",
    "nav.log_out": "Log Out",
    "nav.referrals": "Refer & Earn",
    "nav.create_account": "already have an account?",
    "nav.join_folio": "Join Folio!",

    /*Not Found */
    "404.header": "Something went wrong",
    "404.body": "We couldn't find the page that you were looking for.",
    "404.redirect": "Click here to go home",

    /*Resource Landing Page */
    "resource.cta_download": "Just {0} for unlimited digital downloads from the entire Folio library!",
    "resource.cta_download_price": "$5/month",
    "resource.cta_shipping": "High-quality prints available in several sizes. {0} and always get free shipping!",
    "resource.download_pdf": "Download PDF",
    "resource.popup_per": "per resource",
    "resource.popup_cart": "add to cart",
    "resource.collection_title": "Part of the {0} Collection",
    
    /*Referral */
    "referral.accepted": "Accepted",
    "referral.create_account": "Create an account to begin earning referral rewards!",
    "referral.cta_body": "Invite a friend to join Folio and you both get one free month of Folio!",
    "referral.cta_button": "Send Invites",
    "referral.cta_header": "Refer a teacher and you both save time and money!",
    "referral.days_available": "You have {0} free days available",
    "referral.email_placeholder": "example@example.com",
    "referral.expired": "Expired",
    "referral.how": "How it works",
    "referral.months_earned": "{0} months",
    "referral.months_pending": "{0} months",
    "referral.no_referrals": "You have not referred anyone yet.",
    "referral.pending": "Pending",
    "referral.redeem_body": "You both get 1 month of Folio for Free! Keep inviting more people to accumulate months!",
    "referral.redeem_title": "Redeem free month!",
    "referral.referrals_date": "Date sent",
    "referral.referrals_email": "Recipient email",
    "referral.referrals_header": "Your Referrals",
    "referral.recipient":"Recipient",
    "referral.referrals.status": "Status",
    "referral.registration_body": "When they’re ready they can sign up and start using Folio. Invitations expire after 30 days.",
    "referral.registration_header": "Registration",
    "referral.savings": "You’ve saved a total of ${0} so far.",
    "referral.send_body": "Enter your friends’ email addresses and we’ll send them an invite to sign up.",
    "referral.send_header": "Send Invitation",

    
    /*Register */
    "register.register_account": "Register account",
    
    /*Saves */
    "saved.no_account": "Create a Folio account and start saving resources today!",
    "saved.no_saves": "Add your favorite resources here by selecting the {0} icon on a resource!",

    /*Search */
    "search.placeholder": "Search resources",
    "search.cta": "Find the resources you need!",
    "search.results": "results",
    "search.no_results": "No results",
    "search.no_results__1": "Is this something you'd like to see in Folio? {}!",

    /*Subscribe */
    "subscribe.pricing_yearly": "$48/year. A 20% savings!",
    "subscribe.continue_unsubscribed": "continue with a free account",
    "subscribe.cta": "Subscribe Today!",
    "subscribe.feature_1": "Unlimited digital downloads of hundreds of educational resources",
    "subscribe.feature_3": "World-class support from a team of teachers and experts",
    "subscribe.feature_4": "Refer teachers and earn free months!",
    "subscribe.features_header": "Full Access",
    "subscribe.period_monthly": "Monthly",
    "subscribe.period_yearly": "Yearly",
    "subscribe.pricing_monthly": "Just $5/month",
    "subscribe.terms_1": "Subscriptions auto-renew. Cancel anytime.",
    "subscribe.terms_2": "Contact us with any questions.",
    "subscribe.feature_2": "New resources added regularly",

    /*Success */ 
    "success.subscribed_header": "You’ve successfully subscribed!",
    "success.subscribed_body": "Thanks for joining the Folio community! Finish registration to continue!",
    "success.finish_registration": "Finish Registration!",
    "success.order_header": "You're order has been confirmed!",
    "success.order_body": "Thanks you for your order! You will receive an email with your order details shortly!",
    "success.return_to_folio": "Return to Folio!",
    
    /* Home */
    "home.featured_collections": "Featured Collections",
    "home.new_resources": "New Resources!",
    "home.promo_1": "Check out the Structured Literacy Collection!",

    /*Defs */
    "subject.mth": "Math",
    "subject.par": "Parent Engagement",
    "subject.go": "Graphic Organizers",
    "subject.ell": "English Language Learning",
    "subject.ela": "English Language Arts",
    "subject.sci": "General Science",
    "subject.sel": "Social Emotional Learning",
    "subject.gen": "General Resources",
    "grade.pe": "Primary Elementary",
    "grade.ue": "Upper Elementary",
    "grade.ele": "Elementary",
    "grade.ms": "Middle School",
    "grade.hs": "High School",
    "grade.gen": "General",
    "domain.na": "General",
    "domain.nob": "Numbers & Operations in Base Ten",
    "domain.geo": "Geometry",
    "domain.nof": "Numbers & Operations - Fractions",
    "domain.md": "Measurement & Data",
    "domain.oat": "Operations & Algebraic Thinking",
    "domain.cc": "Counting & Cardinality",
    "domain.ls": "Life Science",
    "domain.ess": "Earth & Space Sciences",
    "domain.ps": "Physical Sciences",
    "domain.ets": "Engineering, Technology, & Applications of Science",
    "domain.lit": "Reading/Literature",
    "domain.rit": "Reading/Informational Text",
    "domain.rfs": "Reading/Foundational Skills",
    "domain.wrt": "Writing",
    "domain.sl": "Speaking & Listening",
    "domain.lng": "Language",
    "domain.rqc": "Standard 10: Range, Quality, and Complexity",
    "domain.ns": "The Number System",
    "size.ltr": "Letter",
    "size.a2": "A2",
    "size.a1": "A1",
    "size.lrg": "Large",
    "size.10sq": "Square",
    "size.a2sq": "A2 Square",
    "size.a1sq": "A1 Square",
    "size.med": "Medium",
    "size.uni": "Unique Sized Resource",
    "size.description_ltr": "Great for self-directed learning, portfolios, and practice opportunities!'",
    "size.description_lrg": "Easily visible for all students. Build classroom walls that teach!'",
    "size.description_med": "Perfect size for small group instruction and personalized learning!'"
  },
  es: {
    /*General */
    "gen.account": "Cuenta",
    "gen.create_account": "Crear cuenta",
    "gen.log_in": "Iniciar sesión",
    "gen.log_in__1": "Iniciar sesión",
    "gen.log_out": "Cerrar sesión",
    "gen.register": "Registro",
    "gen.first_name": "Nombre",
    "gen.last_name": "Apellido",
    "gen.state": "Estado",
    "gen.optional": "opcional",
    "gen.input_error": "Por favor ingresa un valor para este campo",
    "gen.authenticating": "Autenticación",
    "gen.pp": "Políticas de privacidad",
    "gen.tos": "Términos de uso",
    "gen.contact_us": "Contáctanos",
    "gen.recent": "Más recientes",
    "gen.sort_desc": "Títulos: (A-Z)",
    "gen.sort_asc": "Títulos: (Z-A)",
    "gen.bi": "Bilingüe",
    "gen.description": "Descripción",
    "gen.domain": "Dominio",
    "gen.download": "Descarga",
    "gen.en": "Inglés",
    "gen.es": "Español",
    "gen.grade_levels": "Grados sugeridos",
    "gen.order_print": "Compra este recurso",
    "gen.pricing": "$ c/u",
    "gen.related_resources": "Recursos relacionados",
    "gen.save": "Guardar",
    "gen.saved": "¡Guardado!",
    "gen.standard": "Estándar",
    "gen.subject": "Materia",
    "gen.subscribe_to_download": "Suscríbete para descargar",
    "gen.tos_and_pp": "Términos, condiciones y políticas de privacidad",
    "gen.language": "Idioma",
    "gen.quantity": "Cant: {0}",
    "gen.remove": "Eliminar",
    "gen.size": "Tamaño",
    "gen.join": "¡Únete a Folio!",

    "gen.loading": "Cargando",
    "gen.filters": "Filtros",
    "gen.share": "Compartir",
    "gen.grades": "Grados",
    "gen.close": "Cerrar",
    "gen.resource": "recurso",
    "gen.one": "este",
    "gen.earned": "ganados",
    "gen.pending": "pendientes",
    "gen.subscribe": "Suscríbete",


    /*Toast Messages */
    "toast.log_out": "Has cerrado tu sesión satisfactoriamente.",
    "toast.log_in_header": "Ingresaste",
    "toast.log_in_body": "¡Bienvenido a Folio {0}!",
    "toast.log_out_header": "Cerraste sesión",
    "toast.log_out_body": "Has cerrado tu sesión satisfactoriamente.",
    "toast.copied_to_clipboard": "¡Enlace copiado!",
    "toast.create_account_body": "Crea una cuenta para guardar este recurso",
    "toast.create_account_header": "Primero crea una cuenta",
    "toast.resource_added_to_cart_body": "Usa el ícono del carrito en la barra de navegación para continuar con el pago.",
    "toast.resource_added_to_cart_header": "¡Recurso/Recursos agregados al carrito!",
    "toast.resource_downloaded_body": "Revisa tu carpeta de descargas para usarlo",
    "toast.resource_downloaded_header": "¡Recurso descargado!",
    "toast.resource_save_error_header": "No pudimos actualizar este recurso.",
    "toast.resource_saved_body": "Añadido exitosamente a tus recursos guardados",
    "toast.resource_saved_header": "¡Recurso guardado satisfactoriamente!",
    "toast.try_again": "Intenta de nuevo en unos minutos.",
    "toast.collection_saved_header": "Colección guardada",
    "toast.collection_saved_body": "¡Esta colección fue añadida exitosamente!",
    "toast.collection_unsaved_header": "¡Colección eliminada de tus recursos guardados!",
    "toast.collection_unsaved_body": "Esta colección ha sido removida de tus recursos guardados.",
    "toast.collection_download_error_body": "No pudimos actualizar esta colección.",
    "toast.link_copied": "¡Enlace copiado!",
    "toast.referral_body": "¡Gracias por invitar a {0} a Folio!",
    "toast.referral_error_pending": "Hay una invitación pendiente para {0} a Folio. Las invitaciones son válidas durante 7 días.",
    "toast.referral_error_exists": "Ya existe un usuario con ese correo electrónico {0}.",
    "toast.referral_header": "¡Invitación enviada con éxito!",
    "toast.gen_error_header": "Algo salió mal",
    "toast.gen_error_body": "Parece que algo salió mal. Vuelve a intentarlo nuevamente.",
    "toast.resource_download_error_body": "El recurso no pudo ser descargado. Intenta nuevamente en unos minutos.",
    "toast.expired_session_header": "Sesión caducada",
    "toast.expired_session_body": "Tu sesión ha caducado ¡Inicia sesión para continuar!",
    "toast.search_error_header": "Busqueda inválida",
    "toast.search_error_body": "Por favor ingresa un término o frase válidos.",
    "toast.added_to_cart_body": "Usa el ícono del carrito en la barra de navegación para continuar con el pago.",
    "toast.added_to_cart_header": "¡añadido al carrito!",
    
    /*Authentication */
    "auth.create_account": "¿No tienes una cuenta?",
    "auth.email": "Continuar con el correo",
    "auth.log_in": "¿Ya eres miembro?",

    /*Cart */
    "cart.check_out": "Finaliza tu pedido ahora",
    "cart.empty_body": "Prueba este {0} o {1}.",
    "cart.empty_header": "¡No hay nada en tu carrito!",
    "cart.free": "¡Gratis!",
    "cart.new_order_total": "Total de nuevos pedidos: ${0}",
    "cart.order_summary": "Resumen del pedido",
    "cart.order_total": "Total del pedido: ${0}",
    "cart.shipping": "Envío",
    "cart.shipping_disclaimer": "Actualmente solo enviamos dentro de EUA",
    "cart.shopping_cart": "Carrito de compras",
    "cart.subscribe_cta": "¡Suscríbete hoy!",
    "cart.subscriber_promo": "¡Suscríbete y recibe envío gratis en todos los pedidos por solo $5 al mes!",
    "cart.subtotal": "Subtotal ({0} recursos)",
    "cart.tax_disclaimer": "El impuesto se calcula al finalizar la compra",

    /*Contact Us */
    "contact.message_1": "¡Nos encantaría escuchar tu opinión!",
    "contact.message_2": "¿Tienes alguna pregunta, comentario, sugerencia o estás interesado en que tu escuela sea parte de Folio?",
    "contact.message_3": "Llena el formulario y un miembro de nuestro equipo te contactará pronto.",
    "contact.support_email": "Correo: soporte@foliok12.com",

    /*Collection */
    "collection.collection_header": "Colección",
    
    /* Navigation */
    "nav.create_account":"¿Ya tienes una cuenta?",
    "nav.join_folio":"¡Únete a Folio!",
    "nav.home": "Inicio",
    "nav.library": "Biblioteca",
    "nav.collections": "Colecciones",
    "nav.saved": "Guardados",
    "nav.log_out": "Finalizar sesión",
    "nav.referrals": "Refiere y gana",

    /*Not Found */
    "404.header": "Algo salió mal",
    "404.body": "No pudimos encontrar la página que estabas buscando.",
    "404.redirect": "Haz click aquí para ir al inicio",

    /*Referral */
    "referral.accepted": "Aceptado",
    "referral.create_account": "¡Crea una cuenta para comenzar a ganar recompensas por invitado!",
    "referral.cta_body": "¡Invita a un amigo a unirse a Folio y ambos obtendrán un mes gratis!",
    "referral.cta_button": "Enviar invitaciones",
    "referral.cta_header": "¡Refiere a un educador y ambos ahorrarán tiempo y dinero!",
    "referral.days_available": "Tienes {0} días gratis disponibles",
    "referral.email_placeholder": "ejemplo@ejemplo.com",
    "referral.expired": "Vencido",
    "referral.how": "¿Cómo funciona?",
    "referral.months_earned": "{0} meses",
    "referral.months_pending": "{0} meses",
    "referral.no_referrals": "Aún no has recomendado a nadie.",
    "referral.pending": "Pendiente",
    "referral.redeem_body": "¡Ambos obtienen 1 mes de Folio gratis! ¡Sigue invitando a más personas y acumula meses!",
    "referral.redeem_title": "¡Canjear mes gratis!",
    "referral.referrals_date": "Fecha de envío",
    "referral.referrals_email": "Correo de tus invitados",
    "referral.referrals_header": "Tus invitados",
    "referral.recipient":"Invitados",
    "referral.referrals.status": "Estado",
    "referral.registration_body": "Cuando estén listos, pueden registrarse y comenzar a usar Folio. Las invitaciones vencen a los 30 días.",
    "referral.registration_header": "Registro",
    "referral.savings": "Has ahorrado un total de ${0} hasta ahora",
    "referral.send_body": "Ingresa las direcciones de correo electrónico de tus amigos y les enviaremos una invitación para registrarse.",
    "referral.send_header": "Enviar invitación", 

    /*Register */
    "register.register_account": "Registra tu cuenta",

    /*Resource Landing Page */
    "resource.cta_download": "Acceso a descargas ilimitadas de todos los recursos Folio por solo {0}",
    "resource.cta_download_price": "$5 al mes",
    "resource.cta_shipping": "Impresiones de alta calidad disponibles en diferentes tamaños ¡{0} y recibe envíos gratis!",
    "resource.download_pdf": "Descargar PDF",
    "resource.popup_per": "por recurso",
    "resource.popup_cart": "añadido al carrito",
    "resource.collection_title": "Parte de la colección {0}",

    /*Saves */
    "saved.no_account": "¡Crea una cuenta en Folio hoy y empieza a coleccionar recursos!",
    "saved.no_saves": "Agrega tus recursos favoritos seleccionando el ícono que aparece en el recurso",

    /*Search */
    "search.placeholder": "Busca recursos",
    "search.cta": "Encuentra los recursos que necesitas",
    "search.results": "resultados",
    "search.no_results": "No hay resultados",
    "search.no_results__1": "¿Quieres encontrar esto en Folio? {}",

    /*Subscribe */
    "subscribe.pricing_yearly": "$48 anual. Descuento del 20%",
    "subscribe.continue_unsubscribed": "Continúa con una cuenta gratis",
    "subscribe.cta": "¡Suscríbete hoy!",
    "subscribe.feature_1": "Descargas digitales ilimitadas de cientos de recursos educativos",
    "subscribe.feature_3": "Soporte de clase mundial de un equipo de profesores y expertos",
    "subscribe.feature_4": "¡Invita a otros educadores y gana meses gratis!",
    "subscribe.features_header": "Acceso a toda la plataforma",
    "subscribe.period_monthly": "Mensual",
    "subscribe.period_yearly": "Anual",
    "subscribe.pricing_monthly": "Por solo $5 al mes",
    "subscribe.terms_1": "Las suscripciones se renuevan automáticamente. Puedes cancelar en cualquier momento.",
    "subscribe.terms_2": "Contáctanos para cualquier pregunta.",
    "subscribe.feature_2": "Nuevos recursos añadidos regularmente",

    /*Success */
    "success.subscribed_header": "¡Te has suscrito exitosamente!",
    "success.subscribed_body": "¡Gracias por unirte a la comunidad Folio!",
    "success.finish_registration": "Finalizar registro",
    "success.order_header": "¡Tu pedido ha sido confirmado!",
    "success.order_body": "Gracias por tu pedido. Pronto recibirás un correo con los detalles de tu orden.",
    "success.return_to_folio": "Regresar a Folio",

    /* Home */
    "home.featured_collections": "Colecciones destacadas",
    "home.new_resources": "Nuevos recursos",
    "home.promo_1": "¡Visita la colección de Literatura estructurada!",

    /*Defs */
    "subject.mth": "Matemáticas",
    "subject.par": "Recursos para la familia",
    "subject.go": "Organizadores gráficos",
    "subject.ell": "Aprendizaje del lenguaje inglés",
    "subject.ela": "Artes del lenguaje en inglés",
    "subject.sci": "Ciencias",
    "subject.sel": "Aprendizaje socioemocional",
    "subject.gen": "Recursos generales",
    "grade.pe": "Primaria elemental",
    "grade.ue": "Primaria superior",
    "grade.ele": "Educación elemental",
    "grade.ms": "Educación media",
    "grade.hs": "Educación secundaria",
    "grade.gen": "General",
    "domain.na": "General",
    "domain.nob": "Números y operaciones en base diez",
    "domain.geo": "Geometría",
    "domain.nof": "Números y operaciones - fracciones",
    "domain.md": "Medición y datos",
    "domain.oat": "Operaciones y pensamiento algebraico",
    "domain.cc": "Conteo y cardinalidad",
    "domain.ls": "Ciencias biológicas",
    "domain.ess": "Ciencias de la tierra y el espacio",
    "domain.ps": "Ciencias físicas",
    "domain.ets": "Ingeniería, tecnología y aplicaciones de la ciencia",
    "domain.lit": "Lectura/Literatura",
    "domain.rit": "Lectura/Texto informativo",
    "domain.rfs": "Lectura/Destrezas fundamentales",
    "domain.wrt": "Escritura y redacción",
    "domain.sl": "Audición y expresión oral",
    "domain.lng": "Lenguaje",
    "domain.rqc": "Estándar 10: nivel de lectura y complejidad del texto",
    "domain.ns": "El sistema numérico",
    "size.ltr": "Carta",
    "size.a2": "A2",
    "size.a1": "A1",
    "size.lrg": "Grande",
    "size.10sq": "Cuadrado",
    "size.a2sq": "A2 cuadrado",
    "size.a1sq": "A1 cuadrado",
    "size.med": "Mediano",
    "size.uni": "Recurso de tamaño único",
    "size.description_ltr": "¡Excelente para el aprendizaje autodirigido, portafolios y práctica!",
    "size.description_lrg": "Visible fácilmente para todos los estudiantes ¡Construye paredes que enseñen!",
    "size.description_med": "¡Tamaño perfecto para enseñar en grupos pequeños y personalizar el aprendizaje!"
  }
});

try{
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  localization.setLanguage(userData.language);
}
catch(e){
  let defaultLanguage = window.localStorage.getItem('defaultLanguage');
  if(defaultLanguage){
    localization.setLanguage(defaultLanguage);
  }
  else{
    defaultLanguage = navigator.language.substring(0, 2);
    if (defaultLanguage !== "en" || defaultLanguage !== "es"){
      defaultLanguage = "en";
    }
      window.localStorage.setItem('defaultLanguage', defaultLanguage);
  }
}

export default localization;