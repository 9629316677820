import React, { Component } from 'react';
import '../css/content_section.scss';
import helperFunctions from '../tools/helperFunctions';
import LoadingImagePlaceholder from './LoadingImagePlaceholder';
import mixpanel from 'mixpanel-browser';

const userData = JSON.parse(window.localStorage.getItem('userData'))

if(process.env.REACT_APP_ENV === "prod"){
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 
}

class CollectionObject extends Component{

    render(){    
        const refDate = (this.props.obj.updated_ts? this.props.obj.updated_ts:this.props.obj.created_ts)
        const language =  (userData? userData.language:window.localStorage.getItem('defaultLanguage'))
        return(
            <div className="collection-container">

                <button className="collection-object" onClick={()=>{    
                        mixpanel.track("collection_selected",{
                            "title": this.props.obj.title,
                            "uuid": this.props.obj.uuid,
                            "location": window.location.pathname
                        })
                        window.location.href = '/collection/' + helperFunctions.titleToURL(this.props.obj.title);
                    }}>
                    < LoadingImagePlaceholder collection={this.props.obj}/>
                    {helperFunctions.isResourceNew(refDate) &&
                        <div className="new-badge">
                            <span className="new-badge-text" style={{fontSize: (language === 'es'? '9px': '11.7px')}}>{language === 'es'? 'NUEVO':'NEW'}</span>
                            <img className="new-badge-img" alt="This collection was recently added!" src="https://cc-cdn.pss.co/assets/bdg_star_med.svg"/>
                        </div>
                    } 
                </button>
                 <div className="multi-line-label">
                    <h6 className="text-max-line">{(language ==="en"?this.props.obj.title:this.props.obj.title_es)}</h6>
                </div>
            </div>
        )
    }
}

export default CollectionObject;