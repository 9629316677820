import React, { Component, Fragment } from 'react';
import '../css/success_view.scss';
import pub_sub from '../tools/pub_sub';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class SuccessView extends Component {

    render() {
        let urlParams = new URLSearchParams(window.location.search);
        const successType = urlParams.get('source')
        if (successType === null){
            window.location.replace('/');
        }
        else if(successType === "cart"){
             pub_sub.publish.empty_cart();
        }
        mixpanel.track("transaction_completed",{
            type: successType,
            session_id: urlParams.get('session_id')
        })
        return (
            <div className="success-view">
                <div className='success-container'>
                    <img className='success-illustration' alt='Thanks from the Folio Team!' src='https://cc-cdn.pss.co/assets/illustrations/success_illustration.svg'></img>
                    {successType === "subscription"?
                    <Fragment>
                        <h3>{localization['success.subscribed_header']}</h3>
                        <p>{localization['success.subscribed_body']}</p>
                        <button className='default-button blue' onClick={()=>{
                              window.location.replace('/register');
                        }}>{localization['success.finish_registration']}</button>
                    </Fragment>
                    :<Fragment>
                        <h3>{localization['success.order_header']}</h3>
                        <p>{localization['success.order_body']}</p>
                        <button className='default-button blue' onClick={()=>{
                              window.location.replace('/');
                        }}>{localization['success.return_to_folio']}</button>
                    </Fragment>
                }
                </div>
            </div>
        );
    }
}
export default SuccessView;