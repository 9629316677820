import React, { Component} from 'react';
import mixpanel from 'mixpanel-browser';
import '../css/footer.scss';
import { ReactComponent as ArrowIcon } from '../svg/arrow_footer.svg';
//import { ReactComponent as IGIcon } from '../svg/ig_footer.svg';
//import { ReactComponent as TTIcon } from '../svg/tt_footer.svg';
import { ReactComponent as GlobeIcon} from '../svg/globe.svg';
import localization from '../tools/localized_strings';
import helperFunctions from "../tools/helperFunctions"

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 
const userData = JSON.parse(window.localStorage.getItem('userData'))

//setup mixpanel link tracking 
mixpanel.track_links("#footer-pp","pp_selected",{
  source: "footer",
  location: window.location.pathname
})
 mixpanel.track_links("#footer-tos","tos_selected",{
  source: "footer",
  location: window.location.pathname
})
 mixpanel.track_links("#footer-contact","contact_us_selected",{
  source: "footer",
  location: window.location.pathname
})

class Footer extends Component { 
  constructor(props){
    super(props)
   
    this.state= {
      defaultLanguage: (userData? userData.language:window.localStorage.getItem('defaultLanguage')),
      languageMenuOpen: false
    }
  }
   
  render(){
    return (
        <div id='footer' className="footer">
          <div className='footer-tm'>
            <span className='c2'>
              @ 2024 Folio
            </span>
            <div>
              <button className='footer-language-button' 
              onClick={()=>{
                this.setState((state)=>({
                  languageMenuOpen: !state.languageMenuOpen
                }))
              }}
              >
                <GlobeIcon/>
                <span className='c2'>{localization["gen." + this.state.defaultLanguage]}</span>
              </button>
              <div className='footer-language-menu' style={{display: (this.state.languageMenuOpen? "flex":"none")}}>
                <button className="language-menu-item" onClick={()=>{
                  helperFunctions.changeLanguage("en", "footer")
                }}>
                    <input type="checkbox" defaultChecked={this.state.defaultLanguage ==="en"} onChange={(e)=>{
                        helperFunctions.changeLanguage("en", "footer")
                    }}/>
                    <span className='c2'>{localization['gen.en']}</span>
                </button>
                <button className="language-menu-item" onClick={()=>{
                  helperFunctions.changeLanguage("es", "footer")
                }}>
                    <input type="checkbox" defaultChecked={this.state.defaultLanguage ==="es"} onChange={(e)=>{
                        helperFunctions.changeLanguage("es", "footer")
                    }}/>
                    <span className='c2'>{localization['gen.es']}</span>
                </button>
              </div>
            </div>  
          </div>
          <div className='footer-legal'>
            <a id="footer-pp" href="https://cc-cdn.pss.co/public/Folio%20Privacy%20Policy.pdf" target="_blank" rel="noreferrer" className='c2'>{localization['gen.pp']} <ArrowIcon/></a>
            <a id="footer-tos" href="https://cc-cdn.pss.co/public/Folio%20Terms%20of%20Service.pdf" target="_blank" rel="noreferrer" className='c2'>{localization['gen.tos']} <ArrowIcon/></a>
          </div>
          <div className='footer-contact'>
            <a id="footer-contact" className='c2' href='/contact-us'>{localization['gen.contact_us']}</a>
            {/*
            <div className='footer-social'>
                <a href='#' rel="noreferrer" onClick={(e)=>{
                  e.preventDefault()
                 
                  window.open("https://www.tiktok.com/@foliok12","_blank")
                }}>
                <TTIcon/>
              </a>
              <a href='#' rel="noreferrer" onClick={(e)=>{
                e.preventDefault()
                 mixpanel.track("social_link_selected",{
                    source: "footer",
                    type:"instagram",
                    location: window.location.pathname
                  })
                  window.open("https://www.instagram.com/folio.k12/", "_blank")
              }}>
                <IGIcon/>
              </a>
            </div>
            */}
          </div> 
        </div>
    );
  }



}
export default Footer;
  