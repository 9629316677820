import React, {Fragment, Component} from "react";
import helperFunctions from "../tools/helperFunctions";

class LoadingImagePlaceholder extends Component{
    constructor(props){
        super(props)
        this.state = {
            isLoaded: false
        }
    }

    handleImageLoaded(){
        this.setState(()=>({
            isLoaded: true 
        }))
    }

    render(){
        let formattedTitle="";
        let tempURL="";
        if (this.props.resource !== undefined){
            formattedTitle = this.props.resource.title.replaceAll(' ', '_');
            if (this.props.resource.language === "s") {
                formattedTitle = helperFunctions.replaceAccentCharacters(formattedTitle);
            }
            tempURL = encodeURI("https://cc-cdn.pss.co/thumbnails_new/" + this.props.resource.base_title.replaceAll(' ','_')  + "/" + this.props.resource.language + (this.props.preview?"/prvw_":"/tmb_") + formattedTitle + ".png");
            tempURL = tempURL.replaceAll('?', '%3F')
            return(
                <Fragment>
                    {!this.state.isLoaded &&
                        <img className="resource-thumbnail" src={"../img/resource_placeholder_" + this.props.resource.orientation +".svg"} alt={this.props.resource.title} style={{display: this.state.isLoaded? "none":"initial"}}/>
                    }
                    <img className="resource-thumbnail" src={tempURL} 
                        alt={this.props.resource.title} 
                        style={{display: !this.state.isLoaded? "none":"initial"}}
                        onLoad={(this.handleImageLoaded.bind(this))}/>        
                    <div className="image-overlay"/>
                </Fragment>
                
            )   
        }
        else if(this.props.collection !== undefined){
            formattedTitle = this.props.collection.title.replaceAll(' ', '_');
            tempURL = encodeURI("https://cc-cdn.pss.co/collections/" + this.props.collection.title.replaceAll(' ','_')  + "/tmb_" + formattedTitle + ".png");
            tempURL = tempURL.replaceAll('?', '%3F')
            return(
                <Fragment>
                    {!this.state.isLoaded &&
                        <img className="collection-thumbnail" src={"../img/collection_placeholder.png"} alt={this.props.collection.title} style={{display: this.state.isLoaded? "none":"initial"}}/>
                    }
                    <img className="collection-thumbnail" src={tempURL}
                        alt={this.props.collection.title} 
                        style={{display: !this.state.isLoaded? "none":"initial"}}
                        onLoad={(this.handleImageLoaded.bind(this))}/>
                    <div className="image-overlay"/>
                </Fragment>
            )   
        }   
    }

    componentDidUpdate(prevProps){
        if(this.props.resource !== undefined){
            if(this.props.resource !== prevProps.resource){
                this.setState(()=>({
                    isLoaded: false
                }))
            }
        }
        else if(this.props.collection !== undefined){
             if(this.props.collection !== prevProps.collection){
                this.setState(()=>({
                    isLoaded: false
                }))
            }
        }
    }
            

    shouldComponentUpdate(nextProps, nextState){
        if(this.props.resource){
            if((this.state.isLoaded !== nextState.isLoaded) || (nextProps.resource.language !== this.props.resource.language) || (nextProps.resource.title !== this.props.resource.title)){
                return true
            }
        }
        else if(this.props.collection){
            if((this.state.isLoaded !== nextState.isLoaded) || (nextProps.collection.language !== this.props.collection.language) || (nextProps.collection.title !== this.props.collection.title)){
                return true
            }
        }
        return false
    }    
}


export default LoadingImagePlaceholder;