import React, { Component } from 'react';
import promiseHandler from '../tools/promiseHandler'
import helperFunctions from '../tools/helperFunctions';
import '../css/onboarding/subscription.scss';
import '../css/onboarding/onboarding.scss';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const SUBSCRIPTION_TYPES=['monthly', 'yearly', 'trial']

//Setup mixpanel link tracking
mixpanel.track_links("#sub-contact","contact_us_selected",{
    source: "subscription",
    location: window.location.pathname
})
 mixpanel.track_links("#sub-tos","tos_selected",{
    source: "subscription",
    location: window.location.pathname
})
 mixpanel.track_links("#sub-pp","pp_selected",{
    source: "subscription",
    location: window.location.pathname
})

class SubscriptionView extends Component {
     constructor(props) {
        super(props);
        this.state = {
            selectedSubscription: 0
        };
    }
    render() {
        if(window.localStorage.getItem('userData') === null || helperFunctions.getTokenCookie() === ""){
            helperFunctions.removeCredentials(404)
        }

        return (
            <div className="onboarding-view main">
                <div className="onboarding-container subscription">
                    <div className='onboarding-header'>
                        <img onClick={()=>{helperFunctions.removeCredentials(200)}} alt="folio logo" className='logo' src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
                        <button className='free-account' onClick={()=>{this.continueUnpaid()}}>
                                {localization['subscribe.continue_unsubscribed'] + " >"}
                            </button>
                    </div>                    
                    <div className="onboarding-info">
                        <h3><b>{localization['subscribe.cta']}</b></h3>
                        <div className='selection-container subscription'>
                            <span className={"c1 " + (this.state.selectedSubscription === 0?"":"unselected")}>{localization['subscribe.period_monthly']}</span>
                            <label className="switch">
                                <input type="checkbox" onChange={(e)=>{
                                    this.setState(()=>({
                                        selectedSubscription: e.target.checked? 1:0
                                    }),()=>{
                                      mixpanel.track("subscription_type_changed",{
                                        type: SUBSCRIPTION_TYPES[this.state.selectedSubscription]
                                      })  
                                    })
                                }}/>
                                <span className="slider round"></span>
                            </label>
                            <span className={"c1 " + (this.state.selectedSubscription === 1?"":"unselected")}>{localization['subscribe.period_yearly']}</span>
                        </div>
                        <img className='subscription-illustration' src='https://cc-cdn.pss.co/assets/illustrations/subscription_illustration.png' alt='Subscribe to Folio Banner'/>
                        <h4 className='subscription-features'>{localization['subscribe.features_header']}</h4>
                        <ul>
                            <li>{localization['subscribe.feature_1']}</li>
                            <li>{localization['subscribe.feature_2']}</li>
                            <li>{localization['subscribe.feature_3']}</li>
                            <li>{localization['subscribe.feature_4']}</li>
                        </ul>
                        <h4 className={"subscription-pricing " +(this.state.selectedSubscription?"yearly":"monthly")}>{this.state.selectedSubscription? "$48/year. A 20% savings!":"Just $5/month"}</h4>
                        <button className={"subscribe-button default-button " + (this.state.selectedSubscription?"green":"blue")} onClick={()=>{
                            this.createCheckoutSession();
                        }}>{localization['subscribe.cta']}</button>
                        
                        <div className='c2 caption-info'>
                            <span>{localization['subscribe.terms_1']}<br/>
                            <a id="sub-contact" href="/contact-us" rel="noreferrer">{localization['subscribe.terms_2']}</a><br/><br/>
                            </span>
                        </div>

                        <button className='free-account-mobile' onClick={()=>{this.continueUnpaid()}}>{"Continue using Folio without a subscription >"}
                        </button>

                        <span className='c2 folio-legal'>
                            <a id="sub-tos" href="https://cc-cdn.pss.co/public/Folio%20Terms%20of%20Service.pdf" target="_blank" rel="noreferrer">Terms of Service</a> and 
                            <a id="sub-pp" href="https://cc-cdn.pss.co/public/Folio%20Privacy%20Policy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    createCheckoutSession(){
        let checkoutRequest = {
            'method': 'POST',
            'url': '/v1/payments/create-subscription',
            'data': {
                'subscription_type': SUBSCRIPTION_TYPES[this.state.selectedSubscription]
            }
        }
        
        promiseHandler.promiseRequest(checkoutRequest).then((checkoutResponse) => {
            if(checkoutResponse.status === 200){
                mixpanel.track("subscribe_button_selected",{
                    type: SUBSCRIPTION_TYPES[this.state.selectedSubscription]
                })
                window.location.href = checkoutResponse.data.url;
            }
        });
    }

    continueUnpaid(){
        mixpanel.track("continue_as_unpaid_user",{
            source: "subscription_view",
            location: window.location.pathname
        })
        window.location.replace("/?login=true");
    }
}
export default SubscriptionView;