import '../css/home.scss';
import ContentSection from '../components/ContentSection';
import React, { Component, Fragment, useEffect, useState } from 'react';
import promiseHandler from '../tools/promiseHandler'
import pub_sub from '../tools/pub_sub';
import mixpanel from 'mixpanel-browser'
import helperFunctions from '../tools/helperFunctions';
import localization from '../tools/localized_strings';


var userData = JSON.parse(window.localStorage.getItem('userData'));

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const testData = {
  "ctas": [
     {
    "title": "New Data Literacy Collection!",
    "image_src": "https://cc-cdn.pss.co/ctas/data_literacy_collection/desktop_Data_Literacy.png",
    "image_src_mobile": "https://cc-cdn.pss.co/ctas/data_literacy_collection/mobile_Data_Literacy.png",
    "link": "https://foliok12.com/collection/Data_Literacy"
  },
     {
    "title": "The Road to Reading!",
    "image_src": "https://cc-cdn.pss.co/ctas/road_to_reading_blog/road_to_reading_blog_header.png",
    "image_src_mobile": "https://cc-cdn.pss.co/ctas/road_to_reading_blog/road_to_reading_blog_header_m.png",
    "link": "https://medium.com/@renee_81153/personalizing-connections-for-all-students-b6927cefc15e"
  },
    {
    "title": "Meet Folio!",
    "image_src": "https://cc-cdn.pss.co/ctas/welcome_to_folio/welcome_to_folio_header.png",
    "image_src_mobile": "https://cc-cdn.pss.co/ctas/welcome_to_folio/welcome_to_folio_header_m.png",
    "link": "https://medium.com/foliok12/meet-folio-84d02b03d405"
  },
  {
    "title": "Providing Connections Among Languages!",
    "image_src": "https://cc-cdn.pss.co/ctas/renee_blog/renee_blog_header.png",
    "image_src_mobile": "https://cc-cdn.pss.co/ctas/renee_blog/renee_blog_header_m.png",
    "link": "https://medium.com/foliok12/the-road-to-reading-deec400a0180"
  }],
   "promos": [{
    "title": localization['home.promo_1'],
    "image_src": "https://cc-cdn.pss.co/collections/Structured_Literacy/header_Structured_Literacy.png",
    "image_src_mobile": "https://cc-cdn.pss.co/collections/Structured_Literacy/tmb_Structured_Literacy.png",
    "link": "/collection/Structured_Literacy"
  }]
}

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sections: [],
      promoIndex: 0
    }
  }

  render(){
    const contentSections = this.state.sections.map((section, index) =>(
      <div className={"home-row" + (section.id==="new_resources"?" new": "")} key={"home_row_" + index}>
      <Fragment>
        {(index % 3 === 0 && index !== 0 && (testData.promos.length > ((index/3) - 1))) 
          ?<PromoObj promo={testData.promos[(index/3) - 1]} index={index}/>
          :<Fragment>
            <ContentSection info ={"content_section_" + index} section={section}/>
          </Fragment>
        }
      </Fragment>
      </div>
    ));
  
    return (
      <div className="home" id="home"> 
        <CTARow ctas={testData.ctas}/>
        {contentSections}
      </div>
    );
  }

  sortData(){
    return new Promise((resolve, reject)=>{
      this.getResources().then((data)=>{
        var tempSections = [];
        var newResources = [];
          data.resources.forEach(resource => {
            var sectionIndex = tempSections.findIndex(x => x.id === (resource.grade_level + " " + resource.subject + " " + resource.language) );
            if(sectionIndex === -1){
              tempSections.push({
                id: resource.grade_level.split(',')[0] + " " + resource.subject + " " + resource.language,
                content: [resource],
                type: "resources"
              })
            }

            else{
              tempSections[sectionIndex].content.push(resource)
            }

            //Check if resource is new
            if(helperFunctions.isResourceNew(resource.created_ts)){
              newResources.push(resource)
            }
          })
        
        if(newResources.length > 0){
          tempSections.unshift({
            id: 'new_resources',
            content: newResources,
            type: "resources"
          })
        }
        resolve(tempSections)
      });
    })
  }

  getCollections(){
    return new Promise((resolve, reject)=>{
      var collectionsRequest = {
            'method': 'GET',
            'url': '/v1/collections',
        }
        promiseHandler.promiseRequest(collectionsRequest).then((collectionsRequest) => {
            if (collectionsRequest.status === 200) {
                resolve(collectionsRequest.data);
            }
            else{
              reject()
            }
        });
    })
  }


  componentDidMount(){  
    this.sortData().then((response)=>{
      if(response)
      var formattedArray = [];
      response.forEach((section)=>{
        if(section.content.length > 5){
          section.content.sort((a,b)=>{return(helperFunctions.sortByFolioStandard(a,b))});
          formattedArray.push(section)
        }        
      })
      
      this.getCollections().then((response)=>{
        var tempSection = {
            id: "collection_row",
            content: response.collections,
            type: "collections"
        }

        const insertIndex = formattedArray[0].id==="new_resources"?1:0;
        formattedArray.splice(insertIndex,0,tempSection);


        this.setState(()=>({
          sections: formattedArray
        }),()=>{
          if(window.location.search !== ''){
              var urlParams = new URLSearchParams(window.location.search);
              if(urlParams.get('login')){
                pub_sub.publish.new_toast_message({
                      type: 'success',
                      header: localization['toast.log_in_header'],
                      message: localization.formatString(localization['toast.log_in_body'], userData.first_name)
                  })
              }
              else if(urlParams.get('logged_out')){
                pub_sub.publish.new_toast_message({
                    type: 'success',
                    header: localization['toast.log_out_header'],
                    message: localization['toast.log_out_body'],
                })
              }
              window.history.pushState(null,'','/')
            }
        })
      })
    });
  }

  getResources(){
    return new Promise((resolve, reject)=>{
      var resourcesRequest = {
          'method': 'POST',
          'url': '/v1/resources',
          'data':JSON.stringify({
            'language': (userData?userData.language: window.localStorage.getItem('defaultLanguage'))
          })
        }
        promiseHandler.promiseRequest(resourcesRequest).then((resourcesResponse) => {
          if (resourcesResponse.status === 200) {
              resolve(resourcesResponse.data);
          }
          else{
            reject()
          }
        });
    })
  }
}

function CTARow(props){
  const [activeIndex, updateIndex] = useState(0);
  useEffect(()=>{
    helperFunctions.manualScroll({"id":"ctas_row", "direction":activeIndex})
  })

  const ctas = props.ctas.map((obj, index)=>{
    return(
      <button className="cta-item" key ={"cta_" + obj.title + "_" + index} onClick={(e)=>{
          e.preventDefault();

          mixpanel.track("cta_link_selected",{
            title: obj.title,
            link: obj.link
          })

          window.location.replace(obj.link)
  
      }}>
        <img alt={obj.title}className="cta-img"src={obj.image_src}/>
        <img alt={obj.title}className="cta-img-mobile"src={obj.image_src_mobile}/>
      </button>
    )
  })
  return(
    <div className="home-row">
      <div className="ctas-container">
        <button id="arrow_left_ctas_row" onClick={()=>{
          if(activeIndex> 0){
            updateIndex(activeIndex - 1)
          }
        }} className="cta-arrow left"/>
        <div className="ctas-row" id="ctas_row" onScroll={(e)=>helperFunctions.sectionScrollHandler(e)}>
          {ctas}
        </div>
        <button id="arrow_right_ctas_row" onClick={()=>{
          if(activeIndex < testData.ctas.length - 1 ){
            updateIndex(activeIndex + 1)
          }
        }} className="cta-arrow right"/>
      </div>
      <div className = "cta-dots-row">
          {ctas.map((obj, index)=>{
            return(
              <div className={"cta-dot " + (activeIndex === index?"active":"")} key={"cta_dot_" + index} onClick={()=>{
                updateIndex(index)
              }}>
              </div>
            )
          })}
        </div>
    </div>
  )
}

function PromoObj(props){
  var promo = props.promo;
  return(
    <div className="promo-container">
      <h3>{promo.title}</h3>
      <a className="promo-item" href={promo.link}>
          <img alt={promo.title} className="cta-img" src={encodeURI(promo.image_src).replaceAll('?', '%3F')}/>
          <img alt={promo.title} className="cta-img-mobile" src={encodeURI(promo.image_src_mobile).replaceAll('?', '%3F')}/>
      </a>
    </div>
  ) 
}

export default Home;
