import React, { Component, Fragment, useEffect, useState } from 'react';
import promiseHandler from '../tools/promiseHandler';
import '../css/resources_view.scss';
import '../css/content_section.scss';
import ContentObject from '../components/ContentObject';
import LazyLoad from 'react-lazyload';
import defs from '../tools/defs';
import pub_sub from '../tools/pub_sub';
import helperFunctions from '../tools/helperFunctions';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings'

const userData = JSON.parse(window.localStorage.getItem('userData'))
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const gradeOrder = ['pe','ue','ele','ms','hs', 'gen']
const subjectOrder = ['mth', 'ela','par','go','sci']

class ResourcesView extends Component {
    constructor(props){
        super(props)

        this.state={
            selectedSubjectIndex: 0,
            gradeLevels: [],
            gradeLevelIndex: 0,
            resources: [],
            sidebarOpen: false
        }
    }

    render(){
        return(   
            <div id="resources_view" className="resources-view">
                <div id="subject_sidebar" className={'subject-sidebar ' + (this.state.sidebarOpen?"open":"")} >
                    <button className="close-button" onClick={()=>{
                        this.setSidebarState(false)
                    }}>
                        <span className='c1'>
                            Close
                        </span>
                    </button>
                    {subjectOrder.map((key, index)=>{
                        return(
                            <div className={key + " sidebar-item " + (this.state.selectedSubjectIndex === index?"selected":"")}  key={'subject_item_' + key} onClick={()=>{
                                this.setState(()=>({
                                    selectedSubjectIndex: index,
                                }), ()=>{
                                    this.subjectUpdated()
                                    this.setSidebarState(false)
                                })
                            }}>
                                <img className='sidebar-icon' alt={key} src={"../img/subject_icons/" + key +"_icon.svg"}></img>
                                <h5><b>{localization['subject.'+key]}</b></h5>
                            </div>
                        )
                    })}
                </div>

                <div className={"subject-overlay " + (this.state.sidebarOpen?"open":"")} onClick={()=>{
                    this.setSidebarState(false)
                 }}>
                </div>
                <div id='list_container' className={'list-container ' + subjectOrder[this.state.selectedSubjectIndex]} onScroll={(e)=>{this.listScrollDetector(e)}}>
                     <button className={subjectOrder[this.state.selectedSubjectIndex] +' subject-button'} onClick={()=>{
                        this.setSidebarState(true)
                    }}>
                        <img className={"arrow_" + (this.state.sidebarOpen?"up":"down")} alt={"list " + (this.state.sidebarOpen?"open":"closed")} src='https://cc-cdn.pss.co/assets/icons/arrow_white.svg'/>
                        <img className='sidebar-icon' alt={subjectOrder[this.state.selectedSubjectIndex]} src={"../img/subject_icons/" + subjectOrder[this.state.selectedSubjectIndex] +"_icon.svg"}></img>
                        <h5><b>{localization['subject.' +subjectOrder[this.state.selectedSubjectIndex]]}</b></h5>
                    </button>
                    <div className={'subject-header gradient ' + subjectOrder[this.state.selectedSubjectIndex]}>
                        <h1><b>{localization['subject.'+ subjectOrder[this.state.selectedSubjectIndex]]}</b></h1>   
                    </div>
                    <div className='grade-level-section'>
                        {gradeOrder.map(((value, index)=>{
                            if(this.state.gradeLevels.indexOf(value) !== -1){
                                return(
                                    <button className={"grade-level-tab " + subjectOrder[this.state.selectedSubjectIndex] + (this.state.gradeLevelIndex === this.state.gradeLevels.indexOf(value)?" active":"")} key={'grade_level_tab_' + index} onClick={()=>{
                                        this.setState(()=>({
                                            gradeLevelIndex: this.state.gradeLevels.indexOf(value)
                                        }),()=>{
                                            mixpanel.track("grade_selected", {
                                                grade: gradeOrder[this.state.gradeLevelIndex],
                                                subjectOrder: subjectOrder[this.state.selectedSubjectIndex]
                                            })
                                            this.filterResources()
                                        })
                                    }}>
                                        <h3><b>{defs.grade_level[value][0]}</b></h3>
                                        <span className='c2'><b>{localization["grade." +value]}</b></span>

                                    </button>
                                )
                            }
                            else{
                                return null
                            }
                        }))}
                    </div>
                    <div id='resources_list' className="resources-list">
                        <ResourceList resources={this.state.resources}/>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount(){
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('subject')){
            this.setState(()=>({
                selectedSubjectIndex: subjectOrder.indexOf(urlParams.get('subject'))
            }), ()=>{
                this.subjectUpdated()
            })
        }
        else{
            this.subjectUpdated()
        }   
    }

    subjectUpdated(){
        this.getResources().then((resources)=>{
            let gradeLevels = []
            resources.forEach((resource)=>{
                const tempGradeLevels = resource.grade_level.split(",");
                tempGradeLevels.forEach(element => {
                    const domainIndex = gradeLevels.indexOf(element.trim());
                    if (domainIndex === -1){
                        gradeLevels.push(element);
                    }   
                });
            })
            this.setState(()=>({
                gradeLevels: gradeLevels,
                resources: resources,
                gradeLevelIndex: 0
            }), ()=>{
                mixpanel.track("subject_selected", {
                    grade: gradeOrder[this.state.gradeLevelIndex],
                    subject: subjectOrder[this.state.selectedSubjectIndex]
                })
                this.filterResources()
            })
        })
    }

    filterResources(){
        const filteredResources = [];
        this.state.resources.forEach(resource => {
            const tempGradeLevels = resource.grade_level.split(",");
            tempGradeLevels.forEach(element =>{
                if (this.state.gradeLevels[this.state.gradeLevelIndex] === element.trim()){
                    filteredResources.push(resource)
                }
            })
        }); 
        pub_sub.publish.refresh_resources(filteredResources);
    }

    listScrollDetector(e){
        const sections = document.getElementsByClassName("section-container")   
        for(let i= 0; i<sections.length; i++){
            const el = sections[i];
            const top = Math.floor(el.getBoundingClientRect().top);

            const resourcesList = document.getElementById('resources_list');
            const listView = document.getElementById('list_container');

            const bottom = listView.getBoundingClientRect().bottom - Math.ceil(resourcesList.getBoundingClientRect().bottom)

            const trueTop = resourcesList.offsetTop - listView.getBoundingClientRect().top - top ;
            if(bottom > - 48){
                pub_sub.publish.update_domain_index(sections.length - 1)
                break;
            }
            if(trueTop < -148){
                pub_sub.publish.update_domain_index((i === 0?0: i - 1))
                break;
            }
        }
    }

    getResources(){
        return new Promise((resolve, reject)=>{
            let resourcesRequest = {
                'method': 'POST',
                'url': '/v1/resources/subject/' + subjectOrder[this.state.selectedSubjectIndex],
                'data':JSON.stringify({
                    'language': (userData?userData.language: window.localStorage.getItem('defaultLanguage'))
                })
            }

             promiseHandler.promiseRequest(resourcesRequest).then((resourcesResponse) => {
                if (resourcesResponse.status === 200) {
                    resolve(resourcesResponse.data.resources);
                }
                else{
                    reject()
                }
            });
        })
    }

    setSidebarState(props){
        document.body.style.overflow = props?'hidden':'initial';
        this.setState(()=>({
            sidebarOpen: props
        }))
    }
}


function ResourceList(){
    const isMobile = window.innerWidth < 768?true:false;

    const[resources, updateResources] = useState([]);
    useEffect(()=>{
        pub_sub.subscribe.refresh_resources((event, props)=>{
            updateResources(props)
            updateCurrentDomainIndex(0)
            if(isMobile){
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else{
                document.getElementById("list_container").scroll({
                    top: 0,
                    left: 0,
                    behavior: 'instant'
                });
            }
        })

        pub_sub.subscribe.update_domain_index((event, props)=>{
            updateCurrentDomainIndex(props)
        })
    })

    const[currentDomainIndex, updateCurrentDomainIndex] = useState(0)


    let sortedSections = []
    resources.forEach((resource)=>{
        const domainIndex = sortedSections.findIndex(r=> r.id === resource.domain);
        if (domainIndex !== -1){
            sortedSections[domainIndex].resources.push(resource);
        }
        else{
            sortedSections.push({
                id:resource.domain,
                resources:[resource]
            })
        }
    })
    sortedSections.sort((a,b)=>{return(helperFunctions.sortByFolioStandard(a,b))});
    return(
        <Fragment>
            <div id='domain_section' className='domain-section'>
                {sortedSections.map((section, index)=>{
                    return(
                        <button id={"domain_item_" + section.id} key={"domain_item_" + section.id} className={'domain-item' + ((currentDomainIndex === index)? " active":"")} onClick={()=>{
                            scrollToDomain(section.id)
                        }}>

                            {localization["domain."+section.id]}
                        </button>
                    )
                })}
            </div>
            <div id="resources_container" className='resources-container'>
                {sortedSections.map((section)=>{
                    section.resources.sort((a,b)=>{return(helperFunctions.sortByFolioStandard(a,b))});
                    return(
                        <div key={"section_" + section.id}  id={"section_" + section.id} className='section-container'>
                            <h3 className="book">{localization['domain.' + section.id]}</h3>
                            <div className='section-resources'>
                                {section.resources.map((resource, index)=>{
                                    return(
                                        <LazyLoad
                                            height={300}
                                            once
                                            offset={150}
                                            key={"resource_" + index}
                                            scrollContainer={isMobile?"":"#list_container"}
                                        >
                                            <ContentObject obj={resource}/>
                                        </LazyLoad>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}

function scrollToDomain(id){
    const el = document.getElementById("section_" + id);
    const listView = document.getElementById('list_container');
    const resourcesList = document.getElementById('resources_list');
    const trueTop = -(el.parentElement.getBoundingClientRect().top - resourcesList.offsetTop  - el.getBoundingClientRect().top)

    listView.scroll({
        top: trueTop,
        left: 0,
        behavior: 'smooth'
    });
}

export default ResourcesView;