import React, { Component, Fragment } from 'react';
import promiseHandler from '../tools/promiseHandler';
import helperFunctions from '../tools/helperFunctions';
import defs from '../tools/defs';
import pub_sub from '../tools/pub_sub'
import LoadingImagePlaceholder from '../components/LoadingImagePlaceholder';
import LazyLoad from 'react-lazyload';
import ContentObject from '../components/ContentObject';
import { ReactComponent as StarIcon } from '../svg/star_icon.svg';
import { ReactComponent as ShareIcon } from '../svg/share_icon.svg';
import { ReactComponent as CollectionIcon } from '../svg/collection_icon.svg';
import { ReactComponent as RelatedResourceIcon } from '../svg/related_icon.svg';

import ResourcePopup from '../components/ResourcePopup';
import '../css/resource_landing_page.scss';

import mixpanel from 'mixpanel-browser';
import {Helmet} from "react-helmet";
import localization from '../tools/localized_strings';

const userData = JSON.parse(window.localStorage.getItem('userData'));

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class ResourceLandingView extends Component {
    constructor(props) {
        super(props)
        let subscribed= false;

        if(userData){
            if(userData.hasOwnProperty("subscription")){
                if(userData.subscription.status==="active" || userData.subscription.status === 'trialing')
                    subscribed = true
            }
            if(userData.hasOwnProperty("school_subscription")){
                if(userData.school_subscription.subscription_status==="active")
                    subscribed = true
            }
        }

        let defaultLanguage = new URLSearchParams(window.location.search).get('l');
            if(defaultLanguage === ""){
                defaultLanguage = 'e'
            }

        this.state = {
            baseResource: {},
            resources: [],
            selectedSize: "",
            selectedResource: {},
            saved: false,
            subscribed: subscribed,
            popupType: "",
            collectionTitle: "",
            relatedResources: [],
            ordersAvailable: false,
            defaultLanguage: (defaultLanguage === ""?"e":defaultLanguage)
        }

        this.LanguageOptions = this.LanguageOptions.bind(this);
    }
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{"Folio - " + window.location.pathname.split('/')[2]}</title>
                    <meta property="og:url" content={window.location.href}/>
                    <meta property="og:title" content={"Folio - " + window.location.pathname.split('/')[2]}/>
                    <meta property="og:image" content={this.resourcePreviewLink(window.location.pathname.split('/')[2])}/>
                </Helmet>
                {this.state.popupType !== "" &&
                    <ResourcePopup baseResource={this.state.baseResource} resources={this.state.resources} selectedLanguage={this.state.selectedResource.language} type={this.state.popupType}></ResourcePopup>
                }
            <div className={"resource-view main " + (this.state.popupType !== ""?"popup":"") }>
               
                <div className="resource-details-container">
                    { Object.keys(this.state.baseResource).length > 0 && 
                        <Fragment>
                            <Helmet>
                                <meta name="description" content={this.state.selectedResource.description}/>
                                <meta property="og:description" content={this.state.selectedResource.description}/>
                            </Helmet>
                            <div className="resource-preview">
                                <LoadingImagePlaceholder resource={this.state.selectedResource} preview/>
                                 <div id="resource_language" className="language-select">
                                    <this.LanguageOptions/>
                                </div>
                            </div>
                            <div className="info-container">
                                <h2 className="resource-title">{this.state.selectedResource.title}</h2>
                                <div className='resource-info-row'>
                                    {/*Save Resource */}
                                    <button className={"info-row-item " + (this.state.saved?"saved":"save")} onClick={()=>{

                                        if(userData){
                                            let info = {
                                                resourceUUID: this.state.baseResource.base_uuid,
                                                saved: !this.state.saved
                                            }
                                            helperFunctions.favoriteResource(info).then((response)=>{
                                                this.setState((state)=>({
                                                    saved: !state.saved
                                                }))
                                            })
                                        }
                                       
                                        else{
                                            mixpanel.track("failed_saved_resource",{
                                                title: this.state.selectedResource.title,
                                                uuid: this.state.selectedResource.uuid,
                                                error: "User does not have an account"
                                            })
                                            pub_sub.publish.new_toast_message({
                                                type: 'error',
                                                header: localization['toast.create_account_header'],
                                                message: localization['toast.create_account_body'],
                                            })
                                        }
                                    }}>
                                        <StarIcon alt="Select to save this resource"/>
                                        <p className='c2'>{(this.state.saved?localization['gen.saved']:localization['gen.save'])}</p>
                                    </button>
                                    <button className="info-row-item share" onClick={()=>{
                                        this.shareResource()
                                    }}>
                                        <ShareIcon alt="Select to save this resource"/>
                                        <p className='c2'>{localization['gen.share']}</p>
                                    </button>
                                    {this.state.baseResource.grade_level.split(',').map((grade, index)=>{
                                        return(
                                            <div className='info-row-item' key={"grade_" + index}>
                                                <Fragment>
                                                    <p className='c2'>{localization['gen.grades']}</p>
                                                    <h4>{defs.grade_level[grade][0].replaceAll(" ","")}</h4>
                                                </Fragment>

                                            </div>
                                        )
                                    })}
                                    <button className='info-row-item subject' onClick={()=>{window.location.replace('/library/?subject=' + this.state.baseResource.subject)}}>
                                        <img className='sidebar-icon' alt={this.state.baseResource.subject} src={"../img/subject_icons/" + this.state.baseResource.subject +"_icon.svg"}></img>
                                        <p className='p2'>{localization['subject.' + this.state.baseResource.subject]}</p>
                                    </button>

                                </div>
                                {this.state.collectionTitle !=="" &&
                                    <div className="collection-container">
                                        <CollectionIcon/>
                                        <h5 className='collection-title'>{localization.formatString(localization['resource.collection_title'],<a href={'/collection/' + helperFunctions.titleToURL(this.state.collectionTitle)}>{this.state.collectionTitle}</a>)}</h5>
                                    </div>
                                }
                                <div className="get-resource-container">
                                    <div className="download get-option">
                                        <button className="option-cta" onClick={()=>{this.openPopup("download")}}>
                                            <h4>{this.state.subscribed?localization['gen.download']:localization['gen.subscribe_to_download']}</h4>
                                        </button>
                                        {!this.state.subscribed &&
                                            <span className='c1' >{localization.formatString(localization['resource.cta_download'],
                                                <b style={{color:"#009A47", textDecoration:"underline"}}>{localization['resource.cta_download_price']}</b>)}
                                            </span>
                                        }
                                    </div>
                                    {this.state.ordersAvailable &&
                                        <div className="order get-option">
                                            <button className='option-cta'onClick={()=>{this.openPopup('order')}}>
                                                <h4>{localization['gen.order_print']}</h4>
                                            </button>
                                            {!this.state.subscribed &&
                                                <span className='c1'>{localization.formatString(localization['resource.cta_shipping'],
                                                    <a href={userData?"/subscribe":"/create-account"} style={{color:"#1755CB", textDecoration:"underline"}}>{localization['gen.subscribe']}</a>)}
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                                {this.state.selectedResource.description !== '' && 
                                    <div className="resource-description">
                                        <h4>{localization['gen.description']}</h4>
                                        <span className="p2">{this.state.baseResource.description}</span>
                                    </div>
                                }
                                <div className="resource-details">
                                    <span className='p2'>{localization['gen.grade_levels']}: <b>{helperFunctions.formatGrades(this.state.baseResource.grade_level.split(','))}</b></span>
                                    <span className='p2'>{localization['gen.subject']}: <a href={'/library/?subject=' + this.state.baseResource.subject}><b>{localization['subject.' + this.state.baseResource.subject]}</b></a> </span>
                                    <span className='p2'>{localization['gen.domain']}: <b>{localization['domain.' + this.state.baseResource.domain]}</b></span>
                                    <span className='p2'>{localization['gen.standard']}: <b>{this.state.baseResource.ccss}</b></span>

                                </div>
                            </div>
                        </Fragment>
                    }
                    
                </div>
                {this.state.relatedResources.length > 0 &&
                    <div id={"related_resources"} className='related-resources-container'>
                        <span style={{display:"flex",columnGap:"11px", alignItems:"center"}}>
                            <RelatedResourceIcon alt="View Related Resources"/>
                            <h3 className="book">{localization['gen.related_resources']}</h3>
                        </span>
                        <div className='related-resources-row'>
                            {this.state.relatedResources.map((resource, index)=>{
                                return(
                                    <LazyLoad
                                        height={248}
                                        once
                                        offset={150}
                                        key={"related_resource_" + index}
                                    >
                                        <ContentObject obj={resource}/>
                                    </LazyLoad>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            </Fragment>
        );
    }

    componentDidMount(){
        pub_sub.subscribe.download_resource((event, info)=>{
            helperFunctions.downloadResource(info)
        });

        pub_sub.subscribe.close_popup(()=>{
            document.getElementById("popup_container").classList.add('closing');
            setTimeout(() => {
                this.setState(()=>({
                    popupType:""
                }))
            }, 400);
        })

      
        this.getResource().then((response)=>{
            //const sizeOptions = response.base_resource.size.split(',');
            this.setState(()=>({
                resources: response.resources,
                baseResource: response.base_resource,
                selectedResource: response.resources.find(x=>x.language === this.state.defaultLanguage),
                saved: response.favorited,
                collectionTitle: response.collection_title,
                relatedResources: response.related_resources,
               // ordersAvailable: (sizeOptions.findIndex(y=>(y === "med" || y ==="lrg")) >= 0?true:false)
            }));
        });
    }

    openPopup(type){
        
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
        
        
        if(type === "download"){
             if(this.state.subscribed){
                this.setState(()=>({
                    popupType: type
                }))
            }
            else{
                mixpanel.track("subscribe_selected",{
                    source: "resource_landing_page",
                    location: window.location.pathname
                })
                window.location.href = (userData?"/subscribe":"/create-account")
            }
        }
        else{
            this.setState(()=>({
                popupType: type
            }))
        }
    }

    getResource(){
        return new Promise((resolve, reject)=>{
            let resourceRequest = {
                'method': 'POST',
                'url': '/v1/resources/' + window.location.pathname.split('/')[2],
                'data':JSON.stringify({
                    'language': (userData?userData.language: window.localStorage.getItem('defaultLanguage'))
                })
            }
            
            promiseHandler.promiseRequest(resourceRequest).then((resourceResponse) => {
                if (resourceResponse.status === 200) {
                    resolve(resourceResponse.data);
                }
                else if(resourceResponse.status === 404){
                    window.location.replace('/404');
                    
                    reject()
                }
                else{
                    reject()
                }
            });
        })
    }

    shareResource(){
        try{
            if (navigator.share) {
                navigator.share({
                    title: 'Join Folio!',
                    url: window.location.href
                })
            } 
            else {
                navigator.clipboard.writeText(window.location.href).then(() => {
                    pub_sub.publish.new_toast_message({
                        type: 'success',
                        header: localization['toast.link_copied'],
                        message: "",
                    })                
                })
            }

             mixpanel.track("resource_shared",{
              "resource_title": this.state.selectedResource.title,
              "resource_uuid": this.state.selectedResource.uuid,
              "type": (navigator.share?"share_dialog":"copied_to_clipboard")
            })
        }catch(e){
            pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.gen_error_header'],
                message: localization['toast.gen_error_body'],
            })
        }

      
    }

    LanguageOptions(){
        return(
            <Fragment>
                {this.state.resources.find(x=>x.language === 'e') &&
                    <button className={"select-button " + (this.state.selectedResource.language === 'e'? 'selected':'')} onClick={()=>{this.languageSelected('e')} } >
                        <h6>
                            {defs.language['e']}
                        </h6>
                    </button>
                }
                {this.state.resources.find(x=>x.language === 's') &&
                    <button className={"select-button " + (this.state.selectedResource.language === 's'? 'selected':'')} onClick={()=>{this.languageSelected('s')} } >
                        <h6>
                            {defs.language['s']}
                        </h6>
                    </button>
                }
                {this.state.resources.find(x=>x.language === 'b') &&
                    <button className={"select-button " + (this.state.selectedResource.language === 'b'? 'selected':'')} onClick={()=>{this.languageSelected('b')} } >
                        <h6>
                            {defs.language['b']}
                        </h6>
                    </button>
                }
            </Fragment>
        )
    }

    languageSelected(value){
        this.setState((state)=>({
            selectedResource: state.resources.find(x=>x.language === value)
        }))
    }

    resourcePreviewLink(title){
        let tempURL = "";
        let formattedTitle = title.replaceAll(' ', '_');
        if (this.state.defaultLanguage === "s") {
            formattedTitle = helperFunctions.replaceAccentCharacters(formattedTitle);
        }
        tempURL = encodeURI("https://cc-cdn.pss.co/thumbnails_new/" + title.replaceAll(' ','_')  + "/" + this.state.defaultLanguage + "/prvw_" + formattedTitle + ".png");
        tempURL = tempURL.replaceAll('?', '%3F')
        return(tempURL)
    }
}

export default ResourceLandingView;