import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import React, {Suspense} from 'react';
import Home from './views/Home';
import Login from './onboarding/LoginView'
import ResourceLandingView from './views/ResourceLandingView';
import CollectionView from './views/CollectionView';
import AuthView from './views/AuthView';
//import CartView from './views/CartView';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import ListView from './views/ListView';
import ResourcesView from './views/ResourcesView';
import SearchView from './views/SearchView';
import LoadingAnimation from './components/LoadingAnimation';
import SubscriptionView from './onboarding/SubscriptionView';
import RegisterView from './onboarding/RegisterView';
import ReferralView from './views/ReferralView';
import ToastHandler from './components/ToastHandler';
import NotFound from './views/NotFound';
import ContactUs from './views/ContactUs';
import ClaimReward from './views/ClaimReward';
import SuccessView from './views/SuccessView';
import './css/_base.scss';

const App = () => (
  
  <Router>    
      <Suspense fallback={LoadingAnimation}/> 
      <Route path='/' render={()=><LoadingAnimation/>}/>
      <Route path='/' render={()=><ToastHandler/>}/>
      {!useRouteMatch('/(login|authenticate|subscribe|register|create-account|claim-reward)') && 
        <Route path='/' render={()=><NavigationBar/>}/>
      }
      <Switch>
        <Route exact path='/' render={()=><Home/>}/>
        <Route path='/(login|create-account)/' render={()=><Login/>}/>
        <Route exact path='/register' render={()=><RegisterView/>}/>
        <Route exact path='/saved' render={()=>
         <div className="filter-page">
            <ListView title="Saved"/>
          </div>
        }/>
        <Route path='/referrals' render={()=><ReferralView/>}/>
        {/*<Route path='/cart' render={()=><CartView/>}/>*/}
        <Route path='/resource/' render={()=><ResourceLandingView/>}/>
        <Route path='/library' render={()=>
            <ResourcesView title="Resources"/>
        }/>
        <Route path='/search' render={()=><SearchView/>}/>
        <Route path='/authenticate' render={()=><AuthView/>}/>
        <Route path='/subscribe' render={()=><SubscriptionView/>}/>
        <Route path='/collection/' render={()=><CollectionView/>}/>
        <Route path='/collections' render={()=>
          <div className="filter-page">
            {/*<FilterBar/>*/}
            <ListView title="Collections"/>
          </div>
        }/>
        <Route path='/contact-us' render={()=><ContactUs/>}/>
        <Route path='/claim-reward' render={()=><ClaimReward/>}/>
        <Route path='/success' render={()=><SuccessView/>}/>
        <Route path='/404' render={()=><NotFound/>}/>
        <Route path='*' render={()=><NotFound/>}/>    
      </Switch>
      {!useRouteMatch('/(subscribe|register|authenticate)') &&
        <Route path='/' render={()=><Footer/>}/>
      }
  </Router>
)

export default App;
