import React, { Component, Fragment } from 'react';
import promiseHandler from '../tools/promiseHandler';
import defs from '../tools/defs';
import ContentObject from '../components/ContentObject';
import helperFunctions from '../tools/helperFunctions';
import { ReactComponent as StarIcon } from '../svg/star_icon.svg';
import { ReactComponent as ShareIcon } from '../svg/share_icon.svg';
import pub_sub from '../tools/pub_sub';
import '../css/collection.scss';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const userData = JSON.parse(window.localStorage.getItem('userData'));

class CollectionView extends Component {
    constructor(props){
        super(props);

        this.state = {
            collection: {},
            saved: false,
            defaultLanguage: (userData? userData.language:window.localStorage.getItem('defaultLanguage'))

        }
    }
    render(){
        let headerURL, tmbURL;

        if (Object.keys(this.state.collection).length > 0 ){
            let formattedTitle = this.state.collection.title.replaceAll(' ', '_');
            headerURL = encodeURI("https://cc-cdn.pss.co/collections/" + formattedTitle + "/header_" + formattedTitle + ".png");
            headerURL = headerURL.replaceAll('?', '%3F')

            tmbURL = encodeURI("https://cc-cdn.pss.co/collections/" + formattedTitle + "/tmb_" + formattedTitle + ".png");
            tmbURL = tmbURL.replaceAll('?', '%3F')
        }
        return(
            <div className="collection-view main">
                <div className="collection-container">
                     <Helmet>
                        <title>{"Folio - " + window.location.pathname.split('/')[2].replaceAll('_', ' ') + " Collection"}</title>
                        <meta property="og:url" content={window.location.href}/>
                        <meta property="og:title" content={"Folio - " + window.location.pathname.split('/')[2].replaceAll('_', ' ') + " Collection"}/>
                        <meta property="og:image" content={"https://cc-cdn.pss.co/collections/" + window.location.pathname.split('/')[2] + "/header_" + window.location.pathname.split('/')[2] + ".png"}/>
                    </Helmet>
                    {Object.keys(this.state.collection).length > 0 && 
                        <Fragment>
                            <h2 className="collection-title"><b>{(this.state.defaultLanguage ==="en"?this.state.collection.title:this.state.collection.title_es)}</b></h2>
                            
                            <div className="collection-header">
                                <img alt={this.state.collection.title + " Header"} src={headerURL} className="collection-header-img default"/>
                                <img alt={this.state.collection.title + " Header"} src={tmbURL} className="collection-header-img mobile"/>
                            </div>
                            <div className='resource-info-row'>
                                {/*Save Resource */}
                                    <button className={"info-row-item " + (this.state.saved?"saved":"save")} onClick={()=>{
                                        if(userData){
                                            let info = {
                                                collectionUUID: this.state.collection.uuid,
                                                favorited: !this.state.saved
                                            }
                                            helperFunctions.favoriteCollection(info).then((response)=>{
                                                this.setState((state)=>({
                                                    saved: !state.saved
                                                }))
                                            })
                                        }
                                        else{
                                            pub_sub.publish.new_toast_message({
                                                type: 'error',
                                                 header: localization['toast.create_account_header'],
                                                message: localization['toast.create_account_body'],
                                            })
                                        }
                                    }}>
                                        <StarIcon alt="Select to save this resource"/>
                                        <p className='c2'>{(this.state.saved?localization['gen.saved']:localization['gen.save'])}</p>
                                    </button>
                                    <button className="info-row-item share" onClick={()=>{
                                        this.shareCollection()
                                    }}>
                                        <ShareIcon alt="Select to save this resource"/>
                                        <p className='c2'>{localization['gen.save']}</p>
                                    </button>
                            </div>
                            <div className="collection-resources-container">
                                <h3><b>{localization['collection.collection_header']}</b></h3>
                                <div className="collection-resources-list">
                                {this.state.collection.collection_resources.map((obj, index)=>{
                                    return(
                                        <ContentObject obj={obj} key={"collection_resource_" + index}/>
                                    )
                                })}
                                </div>
                            </div>
                            <div className="collection-details">
                                <div className="collection-info">
                                    <h4><b>{localization['gen.grade_levels']}: </b>{defs.grade_level[this.state.collection.grade_level][0]}</h4>
                                    <h4><b>{localization['gen.subject']}: </b>{localization['subject.' + this.state.collection.subject]}</h4>
                                    <h4><b>{localization['gen.domain']}: </b>{localization['domain.' + this.state.collection.domain.split(',')[0]]}</h4>
                                </div>
                                <div className="collection-description">
                                    <h4><b>{localization['gen.description']}</b></h4>
                                    <h4>{this.state.collection.description}</h4>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>         
            </div>
        )
    }

    componentDidMount(){
        this.getCollection().then((response)=>{
        response.collection.collection_resources = response.collection.collection_resources.sort((a, b)=>{
            return (a.step_num - b.step_num);
        })
        
            this.setState({
                collection: response.collection,
                saved: response.favorited
            })
        })
    }

    getCollection(){
        return new Promise((resolve, reject)=>{
        let collectionRequest = {
                'method': 'GET',
                'url': '/v1/collections/' + window.location.pathname.split('/')[2]
            }
            promiseHandler.promiseRequest(collectionRequest).then((collectionResponse) => {
                if (collectionResponse.status === 200) {
                    resolve(collectionResponse.data);
                }
                else if(collectionResponse.status === 404){
                    window.location.replace('/404')
                    reject()
                }
                else{
                    reject()
                }
            });
        })
    }

    shareCollection(){
        try{
            if (navigator.share) {
                navigator.share({
                    title: localization['gen.join'],
                    url: window.location.href
                })
            } 
            else {
                navigator.clipboard.writeText(window.location.href).then(() => {
                    pub_sub.publish.new_toast_message({
                        type: 'success',
                        header: localization['toast.link_copied'],
                        message: "",
                    })                
                })
            }
            mixpanel.track("collection_shared",{
                title: this.state.collection.title,
                uuid: this.state.collection.uuid,
                location: window.location.pathname
            })
        }catch(e){
            pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.gen_error_header'],
                message: localization['toast.gen_error_body'],
            })
        }

      
    }
}
export default CollectionView;