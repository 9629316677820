import React, { Component, Fragment } from 'react';
import promiseHandler from '../tools/promiseHandler';
import '../css/list_view.scss';
import '../css/content_section.scss';
import ContentObject from '../components/ContentObject';
import CollectionObject from '../components/CollectionObject';
import PubSub from '../tools/pub_sub'
import LazyLoad from 'react-lazyload';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const userData = JSON.parse(window.localStorage.getItem('userData'));

class ListView extends Component {
    constructor(props){
        super(props)

        this.state = {
            content: [],
            filters: [],
            filteredContent: []
        }
        this.updateFilter = this.updateFilter.bind(this);
    }


    render(){
        return(
            <div className="list-view main">
                <div className= "list-header">
                    {this.props.title === "Resources" && 
                        <button id="filter_button" className="filter-button" onClick={()=>{
                            document.getElementById('filter_bar').classList.add('slide-in-animation');
                        }}>
                            Filters
                            <img alt="Select your filter options" src="https://cc-cdn.pss.co/assets/filter-icon.svg"/>
                        </button>
                    }
                    {(this.state.content.length > 0) && 
                        <div className="sort-containers">
                            <select name="general-select" id="general-select" onChange={(e)=>{this.sortOptionUpdated(e.target.value)}}>
                                <option value="desc">{localization['gen.sort_desc']}</option>
                                <option value="asc">{localization['gen.sort_asc']}</option>
                                <option value="newest">{localization['gen.recent']}</option>
                            </select>
                        </div>
                    }
                </div>
                {this.props.title==="Saved" &&
                    <Fragment>
                        { this.state.content.length > 0
                        ?<div id="list_container"className= "list-container favorites-list">
                            { 
                            this.state.content.map((obj, index)=>{
                                if(obj.collection_resources !== undefined){
                                    return(<CollectionObject key={"saved_collection_" + index} obj={obj}/>)
                                }
                                else{
                                    return(<ContentObject key={"saved_resource_" + index} obj={obj}/>)
                                }
                            }
                            )}                  
                        </div>
                        :<div className="placeholder-container">
                            <img className="placeholder-illustration" src='https://cc-cdn.pss.co/assets/illustrations/saved_placeholder.png' alt="Add resources to saved!"/>
                            {userData ?
                            <h4>{localization.formatString(localization['saved.no_saves'], <img alt="Use this icon to saved resources."src="https://cc-cdn.pss.co/assets/icons/star_icon.svg"/>)}</h4>
                            :
                            <Fragment>
                                <h4>{localization["saved.no_account"]}</h4>
                                <button className='green' onClick={()=>{
                                    mixpanel.track("create_account_selected",{
                                        source: 'library_view',
                                        location: window.location.pathname 
                                    })
                                    window.location.replace('/create-account');}}>
                                    {localization['gen.create_account']}
                                </button>
                            </Fragment>
                            }
                        </div>
                        }
                
                    </Fragment>
                } 
                {this.props.title==="Collections" &&
                    <div id="list_container"className= "list-container collection-list">
                        { this.state.filteredContent.map((resource, index)=>
                            <CollectionObject key={"collection_" + index} obj={resource} />   
                        )}                  
                    </div>
                }   
                {this.props.title==="Resources" &&
                    <div id="list_container" className= "list-container">
                        { this.state.filteredContent.map((resource, index)=>
                            <LazyLoad
                                height={300}
                                once
                                offset={150}
                                scrollContainer={"#list_container"}

                            >
                                <ContentObject key={"resource_" + index} obj={resource}/>
                            </LazyLoad>
                        )}                  
                    </div>
                } 
            </div>
           
        )
    }

    componentDidMount(){
            this.getContent().then((content)=>{
                this.setState(()=>({
                    content: content
                }),()=>{
                    if(this.state.content.length > 0){
                        this.sortOptionUpdated(document.getElementById('general-select').value);
                    }
                })
            });

            PubSub.subscribe.filters_updated((event, value)=>{
                let index = this.state.filters.indexOf(value)
                let tempFilters = this.state.filters;

                if (index >=0){
                    tempFilters.splice(index, 1)
                }
                else{
                    tempFilters.push(value)
                }
                let tempFilteredContent = this.state.content.filter(this.updateFilter);
                this.setState(()=>({
                    filters: tempFilters,
                    filteredContent: tempFilteredContent
                }), ()=>{
                    try {
                        document.getElementById("list_container").scrollTo({'top': 0, 'behavior':'instant'})
                    } catch (error) {
                        
                    }
                });
            });
    }

    getContent(){
        return new Promise((resolve, reject)=>{
            if(this.props.title === "Saved"){
                if(userData){
                    this.getSaved().then((saves)=>{
                        resolve(saves)
                    })
                }
                else{
                    resolve([])
                }
               
            }
            else if(this.props.title === "Resources"){
                this.getResources().then((resources)=>{
                   resolve(resources)
                })
            }
            else if(this.props.title === "Collections"){
                this.getCollections().then((collections)=>{
                    resolve(collections)
                })
            }
            else{
                reject()
            }
        })
    }

    getSaved(){
        return new Promise((resolve, reject)=>{
            let savesRequest = {
                'method': 'GET',
                'url': '/v1/users/favorites',
            }

             promiseHandler.promiseRequest(savesRequest).then((savesResponse) => {
                if (savesResponse.status === 200) {
                    resolve(savesResponse.data.favorites);
                }
                else{
                    reject()
                }
            });
        })
    }

    getResources(){
        return new Promise((resolve, reject)=>{
            let resourcesRequest = {
                'method': 'POST',
                'url': '/v1/resources',
                'data':JSON.stringify({
                    'language': (userData?userData.language: window.localStorage.getItem('defaultLanguage'))
                })
            }

             promiseHandler.promiseRequest(resourcesRequest).then((resourcesResponse) => {
                if (resourcesResponse.status === 200) {
                    resolve(resourcesResponse.data.resources);
                }
                else{
                    reject()
                }
            });
        })
    }

    getCollections(){
        return new Promise((resolve, reject)=>{
            let collectionsRequest = {
                'method': 'GET',
                'url': '/v1/collections'
            }

             promiseHandler.promiseRequest(collectionsRequest).then((collectionsResponse) => {
                if (collectionsResponse.status === 200) {
                    resolve(collectionsResponse.data.collections);
                }
                else{
                    reject()
                }
            });
        })
    }

    updateFilter(resource){
        if (!this.state.filters.includes(resource.grade_level) && !this.state.filters.includes(resource.subject) && !this.state.filters.includes(resource.language)){
            return resource
        }
    }

    sortOptionUpdated(value){
        let tempArray;
        if(value === "desc"){
            tempArray = this.state.content.sort((a, b)=>{
                let titleA = a.title.toLowerCase();
                let titleB = b.title.toLowerCase();

                if(a.language ==="b")
                    titleA = titleA.substring(4);
                if(b.language ==="b")
                    titleB = titleB.substring(4);

                if(titleA < titleB)
                    return-1;
                else if(titleA > titleB)
                    return 1;
                else
                    return 0;        
            })
        }
        else if(value === "asc"){
             tempArray = this.state.content.sort((a, b)=>{
                let titleA = a.title.toLowerCase();
                let titleB = b.title.toLowerCase();

                if(a.language ==="b"){
                
                    titleA = titleA.substring(4);
                }
                if(b.language ==="b")
                    titleB = titleB.substring(4);

                if(titleA > titleB)
                    return-1;
                else if(titleA < titleB)
                    return 1;
                else
                    return 0;
            })
        }
        else if(value==="newest"){
            tempArray = this.state.content.sort((a, b)=>{
                return(b.created_ts - a.created_ts)
            })
        }

        let filteredArray = tempArray.filter(this.updateFilter)

        this.setState(()=>({
            content: tempArray,
            filteredContent: filteredArray

        }), ()=>{
            try {
                document.getElementById("list_container").scrollTo({'top': 0, 'behavior':'instant'})
            } catch (error) {
                
            }
        })
    }
}
export default ListView;