import React, { Component } from 'react';
import '../css/search_view.scss';
import LazyLoad from 'react-lazyload';
import ContentObject from '../components/ContentObject';
import promiseHandler from '../tools/promiseHandler';
import pub_sub from '../tools/pub_sub';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

const userData = JSON.parse(window.localStorage.getItem('userData'))
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class SearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            resources: null
            
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  handleChange(event) {
    this.setState(()=>({
        query: event.target.value
    }));
  }

  handleSubmit(event) {
    event.preventDefault()
    document.getElementById('search_bar').blur()

    if(this.state.query !== ''){
         let searchRequest = {
            'method': 'POST',
            'url': '/v1/resources/search/' + this.state.query,
            'data': JSON.stringify({
                'language': (userData?userData.language: window.localStorage.getItem('defaultLanguage'))
            })

        }
        promiseHandler.promiseRequest(searchRequest).then((searchResponse) => {
            if (searchResponse.status === 200) {
                let data = searchResponse.data;
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'instant'
                });

                if(data.count > 0){
                    mixpanel.track("user_query",{
                        "query": this.state.query,
                        "results": data.count
                    })
                }
                else{
                    mixpanel.track("no_results",{
                        query: this.state.query
                    })
                }
                this.setState(()=>({
                    resources: data.resources,
                    count: data.count
                }));
            }
            else if(searchResponse.status === 404){
                window.location.replace('/404');
            }
            else{
                pub_sub.publish.new_toast_message({
                    type: 'error',
                    header: localization['toast.gen_error_header'],
                    message:  localization['toast.gen_error_body'],
                })
            }
        });
    }
    else{
        pub_sub.publish.new_toast_message({
            type: 'error',
            header: localization['toast.search_error_header'],
            message: localization['toast.search_error_body'],
        })
    }
  }

  render() {
    return (
        <div className={"search-view " +(this.state.resources === null?"fixed":"")}>
            <form className='search-bar-container' action='' onSubmit={this.handleSubmit}> 
                <input id="search_bar" type='search'className="search-bar" placeholder={localization['search.placeholder']} value={this.state.query} onChange={this.handleChange}/>
            </form>
            {this.state.resources === null
                ?<div className='search-landing'>
                    <h3>{localization['search.cta']}</h3>
                    <img src="https://cc-cdn.pss.co/assets/illustrations/search_placeholder.png" alt="Begin your search here!"/>
                </div>

                :<div id="results_container"className='results-container'>
                    {this.state.resources.length === 0
                        ?<div className='no-results' style={{display:this.state.resources.length === 0?true:false}} >
                            <h3 style={{fontWeight: 400 }}>{localization['search.no_results']}</h3> 
                            <img src='https://cc-cdn.pss.co/assets/illustrations/no_results_placeholder.svg' alt="no results"></img>
                            <h4 style={{fontWeight: "500 !important"}}>Is this something you’d like to see in Folio? <a href='/contact-us'>Contact Us!</a></h4>
                        </div>
                        :<div id ="search_results" className='search-results'>
                            <div className="results-count">
                                <h4>{this.state.resources.length + " " + localization['search.results']}</h4>
                            </div>
                            <div className="result-items">
                                {this.state.resources.map((resource, index)=>{
                                    return(
                                        <LazyLoad
                                            height={300}
                                            once
                                            offset={150}
                                            key={"resource_" + index}
                                        >
                                            <ContentObject obj={resource} queryResource/>
                                        </LazyLoad>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
  }
}
export default SearchView;