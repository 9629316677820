const defs = {
    'grade_level': {
        'pe': ['K – 2nd', 'Primary Elementary'],
        'ue': ['3rd – 5th', 'Upper Elementary'],
        'ele': ['K – 5th', 'Elementary'],
        'ms': ['6th – 8th', 'Middle School'],
        'hs': ['7th –12th', 'High School'],
        'gen': ['K – 8th', 'General']
    },
    'subject': {
        'mth': 'Math',
        'par': 'Parent Engagement',
        'go': 'Graphic Organizers',
        'ell': 'English Language Learners',
        'ela': 'English Language Arts',
        'sci': 'General Science',
        'sel': 'Social Emotional Learning',
        'gen': 'General Resources'
    },
    'domain':{
        'na': 'General',
        'nob': 'Numbers & Operations in Base Ten',
        'geo': 'Geometry',
        'nof': 'Numbers & Operations - Fractions',
        'md': 'Measurement & Data',
        'oat': 'Operations & Algebraic Thinking',
        'cc': 'Counting & Cardinality',
        'ls': 'Life Science',
        'ess': 'Earth & Space Sciences',
        'ps': 'Physical Sciences',
        'ets': 'Engineering, Technology, & Applications of Science',
        'lit': 'Reading/Literature',
        'rit': 'Reading/Informational Text',
        'rfs': 'Reading/Foundational Skills',
        'wrt': 'Writing',
        'sl': 'Speaking & Listening',
        'lng': 'Language',
        'rqc': 'Standard 10: Range, Quality, and Complexity',
        'ns': 'The Number System'
    },
    'language': {
        'e': 'English',
        's': 'Spanish',
        'b': 'Bilingual'
    },
    'size':{
        'ltr': ['Letter', 'Great for self-directed learning, portfolios, and practice opportunities!', '8.5” x 11” (21.59 x 27.94 cm)'],
        'a2': ['A2', ' Perfect size for small group instruction and personalized learning!', '18” x 24” (45.72 x 60.96 cm)'],
        'a1': ['Large', 'Easily visible for all students. Build classroom walls that teach!', '24” x 36” (60.96 x 91.44 cm)'],
        '10sq': ['10 x 10 Square', 'Perfect size for small group instruction and personalized learning!', '18” x 24” (45.72 x 60.96 cm)'],
        'ltrsq': ['Letter Sized Square', 'Great for self-directed learning, portfolios, and practice opportunities!', '8.5” x 11” (21.59 x 27.94 cm)'],
        'a2sq': ['A2 Square',' Perfect size for small group instruction and personalized learning!', '18” x 24” (45.72 x 60.96 cm)'],
        'a1sq': ['A1 Square','Easily visible for all students. Build classroom walls that teach!', '24” x 36” (60.96 x 91.44 cm)'],
        'lrg': ['Large', 'Easily visible for all students. Build classroom walls that teach!', '24” x 36” (60.96 x 91.44 cm)'],
        'med': ['Medium ', 'Perfect size for small group instruction and personalized learning!', '18” x 24” (45.72 x 60.96 cm)'],
        'uni': ['Unique Sized Resource', 'Designed for hands-on activities and learning experiences!', '']
    },

    'price':{
        'lrg': '18.99',
        'med': '14.99'
    }
}

export default defs;