import React, { Component } from 'react';
import '../css/onboarding/onboarding.scss';
import '../css/contact_us.scss';
import pub_sub from '../tools/pub_sub';
import HubspotForm from 'react-hubspot-form'
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class ClaimReward extends Component {
    render() {
        return (
            <div className="onboarding-view main">
                <div className="onboarding-container">
                    <div className='onboarding-header'>
                        <img onClick={()=> window.location.href='/'} alt="folio logo" className='logo' src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
                    </div>
                    <div className="onboarding-info">
                        <div className='contact-header'>
                            <h2><b></b></h2>
                            <br/>
                            <h4 className="c1 contact-message">Thanks for joining Folio!<br/><br/><b>Currently we are unable to process orders of less than $1</b>, so we made this form!<br/><br/> Fill out the form below and we will verify your <b>free medium poster</b> and process your order!<br/><br/>
                            Thanks for understanding during these early days!</h4>
                        </div>
                        <HubspotForm
                            submitButtonClass='cta-button'
                            cssClass='input-container'
                            portalId='7314258'
                            formId='6d055096-fb59-4a48-bd6b-aa0bfdacffbf'
                            onSubmit={()=>{
                                document.getElementsByClassName('contact-header')[0].style.display="none";
                                mixpanel.track("form_submitted",{
                                    form: "Folio Claim Poster",
                                    formID: "6d055096-fb59-4a48-bd6b-aa0bfdacffbf"
                                })
                                
                            }}
                            onReady={()=>{
                                pub_sub.publish.is_loading(false)
                                const script = document.createElement('script');
                                script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                                let jqueryScript = document.getElementsByTagName('script');
                                let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                                if(src.length === 0) {
                                    document.body.appendChild(script)
                                }
                            }}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount(){
        pub_sub.publish.is_loading(true)
    }    
}
export default ClaimReward;