import React, { Component } from 'react';
import '../css/not_found.scss'
import localization from '../tools/localized_strings';

class NotFound extends Component {
    render() {
        return (
            <div className='not-found-view'> 
                <h2><b>{localization['404.header']}</b></h2>
                <p>{localization['404.body']}<br/><a href='/'><b>{localization['404.redirect']}</b></a></p>
            </div>
        );
    }
}
export default NotFound;