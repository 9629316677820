import React, { PureComponent } from 'react';
import mixpanel from 'mixpanel-browser';
import promiseHandler from '../tools/promiseHandler';
import '../css/onboarding/onboarding.scss';
import helperFunctions from '../tools/helperFunctions';
import localization from '../tools/localized_strings';

const userData = JSON.parse(window.localStorage.getItem('userData'))

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class RegisterView extends PureComponent{
  
    render(){    
        if(userData === null || helperFunctions.getTokenCookie() === ""){
            helperFunctions.removeCredentials(404)
        }
        //Check to see if user is already registered
        if(userData.first_name !== "" && userData.last_name !== ""){
           // window.location.replace("/")
        }
        return(
            <div className="onboarding-view main">
                <div className="onboarding-container">
                    <div className='onboarding-header'>
                        <img onClick={()=>{helperFunctions.removeCredentials(200)}} alt="folio logo" className='logo' src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
                        <button className='cta-button go-home' onClick={()=>{helperFunctions.removeCredentials(200)}}>Return to Login</button>
                    </div>
                    <div className="onboarding-info">
                        <h2 style={{marginBottom: "25px"}}><b>{localization['gen.register']}</b></h2>
                        <div className='input-container'>
                            <span className="span" >{localization['gen.first_name']}</span>
                            <input placeholder="Johnny" id="user_first_name" defaultValue={(userData? userData.first_name:"")} onBlur={(e)=>{
                            }}></input>
                            <span className="warning" id="first_name_warning">*{localization['gen.input_error']}</span>
                        </div>
                        <div className='input-container'>
                            <span className="span" >{localization['gen.last_name']}</span>
                            <input placeholder="Appleseed" id="user_last_name" defaultValue={(userData? userData.last_name:"")}></input>
                            <span className="warning" id="last_name_warning">*{localization['gen.input_error']}</span>
                        </div>
                         <div className='input-container'>
                            <span className="span" >{localization['gen.state']}<span className="optional"> - {localization['gen.optional']}</span></span>
                            <select id="user_state">
                                <option></option>
                                <option>Alabama</option>
                                <option>Alaska</option>
                                <option>Arizona</option>
                                <option>Arkansas</option>
                                <option>California</option>
                                <option>Colorado</option>
                                <option>Connecticut</option>
                                <option>Delaware</option>
                                <option>Florida</option>
                                <option>Georgia</option>
                                <option>Hawaii</option>
                                <option>Idaho</option>
                                <option>Illinois</option>
                                <option>Indiana</option>
                                <option>Iowa</option>
                                <option>Kansas</option>
                                <option>Kentucky</option>
                                <option>Louisiana</option>
                                <option>Maine</option>
                                <option>Maryland</option>
                                <option>Massachusetts</option>
                                <option>Michigan</option>
                                <option>Minnesota</option>
                                <option>Mississippi</option>
                                <option>Missouri</option>
                                <option>Montana</option>
                                <option>Nebraska</option>
                                <option>Nevada</option>
                                <option>New Hampshire</option>
                                <option>New Jersey</option>
                                <option>New Mexico</option>
                                <option>New York</option>
                                <option>North Carolina</option>
                                <option>North Dakota</option>
                                <option>Ohio</option>
                                <option>Oklahoma</option>
                                <option>Oregon</option>
                                <option>Pennsylvania</option>
                                <option>Rhode Island</option>
                                <option>South Carolina</option>
                                <option>South Dakota</option>
                                <option>Tennessee</option>
                                <option>Texas</option>
                                <option>Utah</option>
                                <option>Vermont</option>
                                <option>Virginia</option>
                                <option>Washington</option>
                                <option>West Virginia</option>
                                <option>Wisconsin</option>
                                <option>Wyoming</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <div className='input-container'>
                            <span className="span" >{localization['gen.language']} <span className="optional"> - {localization['gen.optional']}</span></span>
                            <select id="user_language">
                                <option value='en'>{localization['gen.en']}</option>
                                <option value='es'>{localization['gen.es']}</option>
                            </select>
                        </div>
                        <button className="cta-button" onClick={()=>{this.registerUser()}}>{localization['register.register_account']}t</button>
                    </div>
                </div>
            </div>
        )
    }

    registerUser(){
        let registerUser = true;
        if(document.getElementById('user_first_name').value === ""){
            document.getElementById('first_name_warning').style.display = "initial"   
            registerUser=false;
        }
        else{
            document.getElementById('first_name_warning').style.display = "none"   
        }
        if(document.getElementById('user_last_name').value === ""){
            document.getElementById('last_name_warning').style.display = "initial"
            registerUser=false
        }
        else{
            document.getElementById('last_name_warning').style.display = "none"
        }

        if(registerUser){
            let registerRequest = {
                'method': 'PUT',
                'url': '/v1/users',
                'data': {
                    first_name: document.getElementById('user_first_name').value,
                    last_name: document.getElementById('user_last_name').value,
                    state: document.getElementById('user_state').value,
                    language: document.getElementById('user_language').value
                }
            }
                        
            promiseHandler.promiseRequest(registerRequest).then((registerResponse) => {
                if (registerResponse.status === 201) {
                    const userData = registerResponse.data.user;
                    window.localStorage.setItem('userData', JSON.stringify(userData));
                    mixpanel.identify(userData.email)
                    mixpanel.people.set({
                    'first_name': userData.first_name,
                    'last_name': userData.last_name,
                    '$email': userData.email,
                    'stripe_id': userData.stripe_id,
                    'stytch_id': userData.stytch_id
                    })
                    mixpanel.track('account_registered')
                    window.location.replace('/')
                }
            })
        }
    }
}

export default RegisterView;