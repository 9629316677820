import React, { Component } from 'react';
import '../css/onboarding/onboarding.scss';
import '../css/contact_us.scss';
import pub_sub from '../tools/pub_sub';
import HubspotForm from 'react-hubspot-form'
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

const form_language={
    "en":"7fd9cf79-2305-4880-ba8d-d280d5ba93ed",
    "es":"bbdc32a6-d216-4074-905d-940306922669"   
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

class ContactUs extends Component {
    render() {
        return (
            <div className="contact-view">
                <div className='contact-container'>
                    <div className='contact-info'>
                        <h2><b>{localization['gen.contact_us']}</b></h2>
                        <span className="p2 contact-message">
                            {localization['contact.message_1']}<br/><br/>
                            {localization['contact.message_2']}<br/><br/>
                            {localization['contact.message_3']}
                        </span>
                        <span className='p2'>
                            {localization['contact.support_email']}
                        </span>

                    </div>
                    <div className='contact-form'>
                        <HubspotForm
                            submitButtonClass='cta-button'
                            cssClass='input-container'
                            portalId='7314258'
                            formId={form_language[localization.getLanguage()]}
                            onSubmit={()=>{
                                document.getElementsByClassName('contact-header')[0].style.display="none";
                            }}
                            onReady={()=>{
                                pub_sub.publish.is_loading(false)
                                const script = document.createElement('script');
                                script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                                let jqueryScript = document.getElementsByTagName('script');
                                let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                                if(src.length === 0) {
                                    document.body.appendChild(script)
                                }
                                if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                                     mixpanel.track("form_submitted",{
                                        form: "Folio Contact Form",
                                        formID: form_language[localization.getLanguage()]
                                    })
                                }
                            }}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount(){
        pub_sub.publish.is_loading(true)
    }    
}
export default ContactUs;