import React, { Component } from 'react';
import { Stytch } from "@stytch/stytch-react";
import pub_sub from '../tools/pub_sub';
import '../css/onboarding/login.scss';
import '../css/onboarding/onboarding.scss';
import localization from '../tools/localized_strings';

const stytchProps = {
    loginOrSignupView: {
        products: ["oauth","emailMagicLinks"],
        emailMagicLinksOptions: {
            loginRedirectURL: process.env.REACT_APP_STYTCH_LOGIN_LINK,
            loginExpirationMinutes: 30,
            signupRedirectURL: process.env.REACT_APP_STYTCH_SIGN_UP_LINK,
            signupExpirationMinutes: 30,
            createUserAsPending: true
        },
        oauthOptions: {
            providers:[{type: 'google'}, {type: 'microsoft'}],
            loginRedirectURL: process.env.REACT_APP_STYTCH_LOGIN_LINK,
            signupRedirectURL: process.env.REACT_APP_STYTCH_SIGN_UP_LINK
        },
    },
    style: {
        fontFamily: '"Mier A"',
        width: "321px",
        primaryColor: "#4B88FF",
        primaryTextColor: "#323030",
        secondaryTextColor: "#888888",
        darkGrey: "#9D9D9D",
        hideHeaderText: true


    },
    publicToken: process.env.REACT_APP_STYTCH_TOKEN
};
class LoginView extends Component {
    render() {
        return (
              <div className="onboarding-view main">
                <div className="onboarding-container">
                    <div className='onboarding-header'>
                        <img onClick={()=> window.location.href='/'} alt="folio logo" className='logo' src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
                         {(window.location.pathname === "/login"?
                                <span className='c1'>{localization['auth.create_account']}?&nbsp;<a href='/create-account'>{localization['gen.create_account']}</a></span>:
                                <span className='c1'>{localization['auth.log_in']}&nbsp;<a href='/login'>{localization['gen.log_in']}</a></span>
                            )}
                    </div>
                    <div className="onboarding-info">
                        <div id="login_container" className="login-container">
                            <h2><b>{(window.location.pathname === "/login"? localization['gen.log_in']:localization['gen.create_account'])}</b></h2>
                            <Stytch
                                publicToken={stytchProps.publicToken}
                                loginOrSignupView={stytchProps.loginOrSignupView}
                                style={stytchProps.style}
                                callbacks={stytchProps.callbacks}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){        
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('session_expired')){
            pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.expired_session_header'],
                message: localization['toast.expired_session_body'],
            })
        }
    }
}
export default LoginView;