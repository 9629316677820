import React, { Component } from 'react';
import ContentObject from './ContentObject';
import CollectionObject from './CollectionObject';
import LazyLoad from 'react-lazyload';
import helperFunctions from '../tools/helperFunctions';
import localization from '../tools/localized_strings';

import '../css/content_section.scss';
import defs from '../tools/defs';

class ContentSection extends Component {
    render(){
        this.props.section.content.sort((a,b)=>{return(helperFunctions.sortByFolioStandard(a,b))})
        let sectionType = this.props.section.id.split(' ');
        let sectionTitle = "";
        if(this.props.section.type === "resources"){
            if(this.props.section.id === "new_resources"){
                sectionTitle = localization['home.new_resources'];
            }
            else{
                sectionTitle=defs.grade_level[sectionType[0]][0] + " " + localization['subject.' + [sectionType[1]]] + ((sectionType[2] === "s")?" (Español)":"")
            }
        }
        else{
            sectionTitle=localization['home.featured_collections'];
        }
       
        return (
            <LazyLoad
                height={424}
                once
                offset={200}
                >
                <div className={"content-section " + (this.props.section.id === "new_resources"?"new":"")} key={"content_section_" + this.props.section.id}>
                    {this.props.section.type === "resources" &&
                        <button id={"arrow_left_" + this.props.section.id} className="row-arrow left" onClick={()=>helperFunctions.manualScroll({"id":this.props.section.id, "direction":"left"})}/>
                    }
                    <h3 className="section-title">{sectionTitle}</h3>
                    <div className="content-row" onScroll={(e)=>helperFunctions.sectionScrollHandler(e)} id={this.props.section.id} >
                        {/*sectionContent*/}
                        {this.props.section.content.map((obj, index) =>(
                            <LazyLoad
                                height={248}
                                once
                                offset={150}
                                overflow={true}
                                key={"content_" + sectionTitle + "_" +index}
                                >
                                {this.props.section.type === "resources"?
                                    <ContentObject key={"content_" + sectionTitle + "_" +index} obj={obj} container={"#section_" + this.props.index} overflow={false}/>
                                    :<CollectionObject key={"content_" + sectionTitle + "_" +index} obj={obj} container={"#section_" + this.props.index} overflow={false}/>
                                }
                            </LazyLoad>
                        ))}
                    </div>
                    {this.props.section.type === "resources" &&
                        <button id={"arrow_right_" + this.props.section.id} onClick={()=>helperFunctions.manualScroll({"id":this.props.section.id, "direction":"right"})} className="row-arrow right"/>
                    }
                </div>
            </LazyLoad>
        );
    }
} 
 
export default ContentSection;